import moment from "moment-business-days";

export function overDue(date) {
  if (moment(date).startOf("day").isSameOrBefore(moment().startOf("day")))
    return true;
  return false;
}
export function overDueExact(date) {
  if (moment(date).startOf("day").isBefore(moment().startOf("day")))
    return true;
  return false;
}

export function getBusinessDays(start, end) {
  if (moment(start).startOf("day").isSame(moment(end).startOf("day")))
    return 1 + " Tag";
  var diff = moment(end).businessDiff(moment(start).startOf("day"));
  if (diff === 1) return diff + " Tag";
  return diff + " Tage";
}

export function getBusinessDaysDelay(start, end) {
  if (moment(start).startOf("day").isSame(moment(end).startOf("day")))
    return 1 + " Tag";
  var diff = moment(end)
    .startOf("day")
    .businessDiff(moment(start).startOf("day"));
  if (diff === 1) return diff + " Tag";
  return diff + " Tage";
}

export function getDurationInHours(start, end) {
  var houresDiff = moment(end).diff(moment(start), "hours");
  return houresDiff + "h";
}

export function getDurationInHoursInt(start, end) {
  var houresDiff = moment(end).diff(moment(start), "hours");
  return parseInt(houresDiff);
}

export function getDurationInMinutesInt(start, end) {
  var minutesDiff = moment(end).diff(moment(start), "minutes");
  return parseInt(minutesDiff);
}

export function getDurationInSecondsInt(start, end) {
  var secondsDiff = moment(end).diff(moment(start), "seconds");
  return parseInt(secondsDiff);
}

export function getBusinessDaysInt(start, end) {
  if (moment(start).startOf("day").isSame(moment(end).startOf("day"))) return 1;
  var diff = moment(end).businessDiff(moment(start).startOf("day"));
  return parseInt(diff);
}

export function getBusinessDaysDelayInt(start, end) {
  if (moment(start).startOf("day").isSame(moment(end).startOf("day"))) return 1;
  var diff = moment(end)
    .startOf("day")
    .businessDiff(moment(start).startOf("day"));
  return parseInt(diff);
}

export function getDurationInHoursAndMinutes(start, end) {
  var hoursDiff = moment(end).diff(moment(start), "hours");
  var minutesDiff = moment(end).diff(moment(start), "minutes");
  minutesDiff = minutesDiff - hoursDiff * 60;
  return hoursDiff + "h " + minutesDiff + "m";
}

export function getDurationInHoursAndMinutesAndSeconds(start, end) {
  var secondsDiff = moment(end).diff(moment(start), "seconds");
  return moment.utc(secondsDiff * 1000).format("HH:mm:ss");
}

export function getDurationInHoursAndMinutesFromMinutes(minutes) {
  let durationOne = moment.duration(parseInt(minutes), "minutes");
  return durationOne.asHours() + "h";
}

export function checkForBreakTimes(worktime, breaktime) {
  if (worktime >= 21600 && worktime < 32400) {
    // Arbeitszeit mehr oder gleich 6 Stunden und weniger als 9 Stunden (min. 30 Minuten Pause)
    if (breaktime >= 1800) {
      return false;
    } else {
      return true;
    }
  } else if (worktime > 32400) {
    // Arbeitszeit mehr als 9 Stunden (min. 45 Minuten Pause)
    if (breaktime >= 2700) {
      return false;
    } else {
      return true;
    }
  } else if (worktime < 21600) {
    return false;
  }
}
