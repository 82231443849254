import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { useUsers } from "./useUsers";
import { GET_ALL_TIMES } from "../queries/getAllTimes";
import { ON_TIMES_CHANGED } from "../subscriptions/onTimesChanged";
import { CHECK_IN } from "../mutations/checkIn";
import { CHECK_OUT } from "../mutations/checkOut";
import { REMOVE_TIME } from "../mutations/removeTime";
import moment from "moment/moment";
import { CONTINUE_TIME } from "../mutations/continueTime";
import { UPDATE_TASK } from "../mutations/updateTask";
import { UPDATE_TIME } from "../mutations/updateTime";
import { GET_ARCHIVED_TIMES } from "../queries/getArchivedTimes";
import { GET_TASK_BY_ID } from "../queries/getTaskById";

export const useTimes = () => {
  const { me } = useUsers();
  const fetchQuery = GET_ALL_TIMES;

  const {
    data: dataQuery,
    loading: loadingQuery,
    error: errorQuery,
    refetch: refetchQuery,
  } = useQuery(fetchQuery, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  const { data: allTimesSubData } = useSubscription(ON_TIMES_CHANGED, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  let timeData = allTimesSubData
    ? allTimesSubData?.onTimesChanged
    : dataQuery
      ? dataQuery?.getAllTimes
      : [];

  //console.log("TaskQuery", allTasksSubData?.onTasksChanged, dataQuery?.getAllTasks)

  const [checkIn, { error: errorCheckIn }] = useMutation(CHECK_IN, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("time checked in!");
    },
  });

  const [checkOut, { error: errorCheckOut }] = useMutation(CHECK_OUT, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("time checked out!");
    },
  });

  const [continueTime, { error: errorContinueTime }] = useMutation(
    CONTINUE_TIME,
    {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
      onCompleted: (res) => {
        if (!res) return;
        refetchQuery();
        console.log("time continued!");
      },
    },
  );

  const [remove, { error: errorRemove }] = useMutation(REMOVE_TIME, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("time removed!");
    },
  });

  const [update, { error: errorUpdate }] = useMutation(UPDATE_TIME, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("time updated!");
    },
  });

  const [archivedTimes] = useLazyQuery(GET_ARCHIVED_TIMES, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      //refetchQuery()
      console.log("tasks found!");
    },
  });

  return {
    times: timeData,
    archivedTimes: () => archivedTimes(),
    myTimes: timeData?.filter((time) => time.user._id === me?.me._id),
    firstTimeOfDay: timeData.find(
      (time) =>
        time.user._id === me?.me._id &&
        moment().startOf("day").isSame(moment(time.starttime).startOf("day")),
    ),
    currentTime: timeData.filter(
      (time) =>
        time.user._id === me?.me._id &&
        moment().startOf("day").isSame(moment(time.starttime).startOf("day")),
    )[
      timeData.filter(
        (time) =>
          time.user._id === me?.me._id &&
          moment().startOf("day").isSame(moment(time.starttime).startOf("day")),
      ).length - 1
    ],
    loading: loadingQuery,
    error:
      errorQuery ||
      errorRemove ||
      errorCheckIn ||
      errorCheckOut ||
      errorContinueTime,
    checkIn: (input) =>
      checkIn({
        variables: {
          input: input,
        },
      }),
    checkOut: (id) =>
      checkOut({
        variables: {
          _id: id,
        },
      }),
    continueTime: (id) =>
      continueTime({
        variables: {
          _id: id,
        },
      }),
    updateTime: (input, id) =>
      update({
        variables: {
          input: input,
          _id: id,
        },
      }),
    removeTime: (id) =>
      remove({
        variables: {
          _id: id,
        },
      }),
  };
};
