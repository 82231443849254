import { gql } from "@apollo/client";

export const TASK_ENTITY = gql`
  fragment TaskEntity on TaskEntity {
    _id
    assigned
    customer
    street
    number
    plz
    city
    long
    lat
    radius
    created_at
    updated_at
  }
`;
