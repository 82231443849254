import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTasks } from "../../apollo/hooks/useTasks";
import { useUsers } from "../../apollo/hooks/useUsers";
import styles from "./time.module.css";
import { shadeColor } from "../../shade";
import { useTimes } from "../../apollo/hooks/useTimes";
import { getDurationInHoursAndMinutesAndSeconds } from "../../dateFunctions";
import { checkIfPointInRadius } from "../Map/checkIfPointInRadius";
import { BsCarFrontFill, BsFillDoorOpenFill } from "react-icons/bs";
import { BiSolidCoffee } from "react-icons/bi";
import { MdOutlineConstruction } from "react-icons/md";
import { CiStopwatch } from "react-icons/ci";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "60vh",
  overflowY: "auto",
  minWidth: 300,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
};

const Time = (props) => {
  const { tasks, loading: tasksLoading, error: tasksError } = useTasks();
  const { me, loading: meLoading, error: meError } = useUsers();
  const {
    firstTimeOfDay,
    currentTime,
    checkIn,
    checkOut,
    loading: timesLoading,
    error: timesError,
  } = useTimes();

  const [checkInModal, setCheckInModal] = useState(false);
  const [checkOutModal, setCheckOutModal] = useState(false);

  const [color, setColor] = useState("e15d73");
  const [timeRunning, setTimeRunning] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState("Lade Daten...");
  const [currentTimeElapsed, setCurrentTimeElapsed] = useState("Lade Daten...");

  useEffect(() => {
    if (currentTime?.endtime || currentTime === undefined) {
      setColor("#e15d73");
      setTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          firstTimeOfDay?.starttime,
          moment(),
        ),
      );
      setCurrentTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          currentTime?.starttime,
          moment(),
        ),
      );
      setTimeRunning(false);
    } else {
      setColor("#40c935");
      setTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          firstTimeOfDay?.starttime,
          moment(),
        ),
      );
      setCurrentTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          currentTime?.starttime,
          moment(),
        ),
      );
      setTimeRunning(true);
    }
  }, [currentTime, currentTimeElapsed]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          firstTimeOfDay?.starttime,
          moment(),
        ),
      );
      setCurrentTimeElapsed(
        getDurationInHoursAndMinutesAndSeconds(
          currentTime?.starttime,
          moment(),
        ),
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTime, currentTimeElapsed]);

  if (tasksLoading || meLoading || timesLoading) return;
  if (tasksError || meError || timesError) return;

  const handleCheckIn = (task, type) => {
    if (type === "CHECKINCHECKOUT") {
      let selectedTask = tasks.find((x) => x._id === task._id);
      navigator.geolocation.getCurrentPosition(function (position) {
        //check if in location
        if (
          checkIfPointInRadius(
            [selectedTask.long, selectedTask.lat],
            selectedTask.radius,
            [position.coords.longitude, position.coords.latitude],
          )
        ) {
          const payload = {
            user: me.me._id,
            task: selectedTask._id,
            type: type,
          };
          if (currentTime && !currentTime.endtime) {
            checkOut(currentTime._id).then((res) => {
              if (res.data.checkOut._id) {
                checkIn(payload).then(() => {
                  setCheckInModal(false);
                  setCheckOutModal(false);
                });
              }
            });
          } else {
            checkIn(payload).then(() => {
              setCheckInModal(false);
              setCheckOutModal(false);
            });
          }
        } else {
          alert("Du befindest dich nicht im vorgegebenen Bereich.");
        }
      });
    } else {
      const payload = {
        user: me.me._id,
        task: null,
        type: type,
      };
      if (currentTime && !currentTime.endtime) {
        checkOut(currentTime._id).then((res) => {
          if (res.data.checkOut._id) {
            checkIn(payload).then(() => {
              setCheckInModal(false);
              setCheckOutModal(false);
            });
          }
        });
      } else {
        checkIn(payload).then(() => {
          setCheckInModal(false);
          setCheckOutModal(false);
        });
      }
    }
  };

  const handleCheckOut = () => {
    if (
      window.confirm(
        "Möchtest du wirklich auschecken? Danach kannst du dich für den heutigen Tag nicht nochmal einchecken.",
      )
    ) {
      if (currentTime.type === "CHECKINCHECKOUT") {
        navigator.geolocation.getCurrentPosition(function (position) {
          let selectedTask = tasks.find((x) => x._id === currentTime.task._id);
          if (
            checkIfPointInRadius(
              [selectedTask.long, selectedTask.lat],
              selectedTask.radius,
              [position.coords.longitude, position.coords.latitude],
            )
          ) {
            checkOut(currentTime._id).then(() => {
              setCheckInModal(false);
              setCheckOutModal(false);
            });
          } else {
            alert("Du befindest dich nicht im vorgegebenen Bereich..");
          }
        });
      } else {
        checkOut(currentTime._id).then(() => {
          setCheckInModal(false);
          setCheckOutModal(false);
        });
      }
    }
  };

  return (
    <Box
      sx={{ display: props.disableActions && !timeRunning ? "none" : "block" }}
    >
      <Box
        onClick={() =>
          !props.disableActions &&
          (timeRunning ? setCheckOutModal(true) : setCheckInModal(true))
        }
        className={styles.navButton}
        display={"flex"}
        alignItems={"center"}
        p={0}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          spacing={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Card sx={{ borderRadius: "5px 0px 0px 5px" }}>
            <CardContent
              sx={{
                backgroundColor: color,
                borderRight: "1px solid " + shadeColor(color, -10),
              }}
            >
              <Box p={1}>
                <Box
                  className={styles.iconWrapper}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography
                    style={{ lineHeight: "0px", zIndex: 3 }}
                    color={shadeColor(color, 150)}
                    variant={"h4"}
                  >
                    <CiStopwatch />
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Stack direction={"column"} spacing={1}>
            <Typography variant={"h7"}>
              {timeRunning ? (
                <Stack direction={"column"}>
                  <Typography variant={"body2"}>Heute gesamt</Typography>
                  <Typography variant={"h7"}>{timeElapsed}</Typography>
                </Stack>
              ) : (
                "CheckIn"
              )}
            </Typography>

            {timeRunning ? (
              <Stack direction={"column"}>
                <Typography variant={"body2"}>Aktuelle Zeit</Typography>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  <Typography
                    variant={"h7"}
                    style={{ fontVariant: "tabular-nums" }}
                  >
                    {currentTimeElapsed}
                  </Typography>
                  <Typography variant={"body1"}>
                    {currentTime?.type === "DRIVE" ||
                    currentTime?.type === "BREAK" ||
                    currentTime?.type === "HAENDLER"
                      ? currentTime?.type
                      : currentTime?.task.customer}
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Typography variant={"body2"}>Zeiterfassung starten</Typography>
            )}
          </Stack>
        </Stack>
      </Box>

      <Modal open={checkInModal} onClose={() => setCheckInModal(false)}>
        <Box p={1} sx={{ ...style }}>
          <Stack direction={"column"} spacing={1}>
            {currentTime?.endtime &&
              moment().isAfter(moment(currentTime?.endtime)) && (
                <Box>
                  <Alert severity={"info"}>
                    Du hast dich für heute bereits ausgecheckt.
                  </Alert>
                </Box>
              )}
            {!(
              currentTime?.endtime &&
              moment().isAfter(moment(currentTime?.endtime))
            ) && (
              <Button
                disabled={
                  currentTime?.endtime &&
                  moment().isAfter(moment(currentTime?.endtime))
                }
                fullWidth
                onClick={() => handleCheckIn(null, "DRIVE")}
                size={"large"}
              >
                <Stack direction={"column"}>
                  <Typography variant={"h4"} color={"inherit"}>
                    <BsCarFrontFill />
                  </Typography>
                  <Typography variant={"h7"} color={"inherit"}>
                    Fahrt
                  </Typography>
                </Stack>
              </Button>
            )}
            {!(
              currentTime?.endtime &&
              moment().isAfter(moment(currentTime?.endtime))
            ) &&
              tasks
                .filter((task) =>
                  task.assigned.some((assigned) => assigned === me.me._id),
                )
                .map((x, index) => (
                  <Button
                    disabled={
                      currentTime?.endtime &&
                      moment().isAfter(moment(currentTime?.endtime))
                    }
                    onClick={() => handleCheckIn(x, "CHECKINCHECKOUT")}
                    size={"large"}
                    key={index}
                  >
                    <Stack direction={"column"}>
                      <Typography variant={"h4"} color={"inherit"}>
                        <MdOutlineConstruction />
                      </Typography>
                      <Typography variant={"h7"} color={"inherit"}>
                        {x?.customer}
                      </Typography>
                    </Stack>
                  </Button>
                ))}
          </Stack>
        </Box>
      </Modal>
      <Modal open={checkOutModal} onClose={() => setCheckOutModal(false)}>
        <Box p={1} sx={{ ...style }}>
          <Stack direction={"column"} spacing={1}>
            <Card>
              <CardContent>
                <Box p={2}>
                  <Typography textAlign={"center"} variant={"h4"}>
                    {timeElapsed}
                  </Typography>
                  <Typography textAlign={"center"} variant={"body1"}>
                    {currentTime?.type === "DRIVE" ||
                    currentTime?.type === "BREAK" ||
                    currentTime?.type === "HAENDLER"
                      ? currentTime?.type + " - "
                      : currentTime?.task.customer + " - "}
                    {currentTimeElapsed}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
            <Stack direction={"row"} spacing={1}>
              {currentTime?.type !== "BREAK" && (
                <Button
                  fullWidth
                  onClick={() => handleCheckIn(null, "BREAK")}
                  size={"large"}
                >
                  <Stack direction={"column"}>
                    <Typography variant={"h4"} color={"inherit"}>
                      <BiSolidCoffee />
                    </Typography>
                    <Typography variant={"h7"} color={"inherit"}>
                      Pause
                    </Typography>
                  </Stack>
                </Button>
              )}
              {currentTime?.type !== "DRIVE" && (
                <Button
                  fullWidth
                  onClick={() => handleCheckIn(null, "DRIVE")}
                  size={"large"}
                >
                  <Stack direction={"column"}>
                    <Typography variant={"h4"} color={"inherit"}>
                      <BsCarFrontFill />
                    </Typography>
                    <Typography variant={"h7"} color={"inherit"}>
                      Fahrzeit
                    </Typography>
                  </Stack>
                </Button>
              )}
            </Stack>
            {currentTime?.type !== "CHECKINCHECKOUT" &&
              tasks
                .filter((task) =>
                  task.assigned.some((assigned) => assigned === me.me._id),
                )
                .map((x, index) => (
                  <Button
                    onClick={() => handleCheckIn(x, "CHECKINCHECKOUT")}
                    size={"large"}
                    key={index}
                  >
                    <Stack direction={"column"}>
                      <Typography variant={"h4"} color={"inherit"}>
                        <MdOutlineConstruction />
                      </Typography>
                      <Typography variant={"h7"} color={"inherit"}>
                        {x?.customer}
                      </Typography>
                    </Stack>
                  </Button>
                ))}
            <Button
              fullWidth
              color={"secondary"}
              onClick={() => handleCheckOut()}
              size={"large"}
            >
              <Stack direction={"column"}>
                <Typography variant={"h4"} color={"inherit"}>
                  <BsFillDoorOpenFill />
                </Typography>
                <Typography variant={"h7"} color={"inherit"}>
                  CheckOut
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default Time;
