import { gql } from "@apollo/client";
import { USER_ENTITY } from "../entities/userEntity";
import { TIME_ENTITY } from "../entities/timeEntity";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";

export const GET_USER_BY_ID = gql`
  query getUserById($_id: String!){
    getUserById(_id: $_id) {
        ...UserEntity
        times {
          ...TimeEntity
        }
        excuses {
          ...ExcuseEntity
        }
    }
  }
  ${USER_ENTITY},${TIME_ENTITY},${EXCUSE_ENTITY}
`;
