import { useTimes } from "../../../apollo/hooks/useTimes";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  checkForBreakTimes,
  getDurationInHoursAndMinutesAndSeconds,
  getDurationInSecondsInt,
} from "../../../dateFunctions";
import { useState } from "react";
import { useUsers } from "../../../apollo/hooks/useUsers";
import {
  BsArrowReturnRight,
  BsArrowRight,
  BsCarFrontFill,
  BsFillPauseFill,
  BsFillPlayFill,
  BsFillTrash3Fill,
} from "react-icons/bs";
import TimeType from "../TimeType";
import { BiSolidCoffee } from "react-icons/bi";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { containerElement, itemElement } from "../../../stagger";
import { AnimatePresence, motion } from "framer-motion";
import TimeChart from "../TimeChart";
import User from "../../common/User";
import Customer from "../../common/Customer";
import EmployeeHourExport from "./EmployeeHoursExport";
import { FaCheck, FaStore } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import { TimePicker } from "@mui/x-date-pickers";
import { useTasks } from "../../../apollo/hooks/useTasks";
import { useExcuses } from "../../../apollo/hooks/useExcuses";
import timeType from "../TimeType";

const RenderTimes = ({
  timeArray,
  me,
  continueTime,
  checkOut,
  removeTime,
  updateTime,
  tasks,
}) => {
  const [editTimeId, setEditTimeId] = useState(null);
  const [editStartTime, setEditStartTime] = useState(null);
  const [editEndTime, setEditEndTime] = useState(null);
  const [editTimeType, setEditTimeType] = useState(null);
  const [editTimeTask, setEditTimeTask] = useState(null);

  const handleSaveEdit = (time) => {
    let editTaskValue = null;
    if (
      editTimeTask !== "DRIVE" &&
      editTimeTask !== "BREAK" &&
      editTimeTask !== undefined
    ) {
      editTaskValue = editTimeTask || time.task?._id;
    } else {
      editTaskValue = null;
    }

    const payload = {
      task: editTaskValue,
      type: editTimeType || time.type,
      starttime: editStartTime || time.starttime,
      endtime: editEndTime || time.endtime,
    };
    updateTime(payload, editTimeId).then(() => {
      setEditTimeId(null);
      setEditStartTime(null);
      setEditEndTime(null);
      setEditTimeType(null);
      setEditTimeTask(null);
    });
  };

  const handleCancelEdit = () => {
    setEditTimeId(null);
    setEditStartTime(null);
    setEditEndTime(null);
    setEditTimeType(null);
    setEditTimeTask(null);
  };

  return (
    <Accordion sx={{ boxShadow: "none" }}>
      <Card sx={{ borderRadius: "0px", border: "none" }}>
        <CardContent sx={{ borderRadius: "0px", border: "none" }}>
          <Box pr={1}>
            <AccordionSummary expandIcon={<IoIosArrowDown />}>
              <Stack
                width={"100%"}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={1}
                pl={2}
              >
                <Typography variant={"h7"}>Protokoll</Typography>
                <Box>
                  <Chip
                    variant="outlined"
                    label={timeArray.length + " erfasste Zeit(en)"}
                  />
                </Box>
              </Stack>
            </AccordionSummary>
          </Box>
        </CardContent>
      </Card>
      <AccordionDetails sx={{ overflowX: "auto" }}>
        <Table size={"small"}>
          <TableBody>
            {timeArray.map((time, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack direction={"column"} spacing={editTimeId ? 1 : 0.5}>
                      {editTimeId === time._id ? (
                        <FormControl fullWidth size={"small"}>
                          <InputLabel>Zeit-Typ</InputLabel>
                          <Select
                            onChange={(event) =>
                              setEditTimeTask(event.target.value)
                            }
                            value={
                              editTimeTask
                                ? editTimeTask
                                : time.task
                                  ? time.task._id
                                  : time.type
                            }
                            label="Zeit-Typ"
                          >
                            <MenuItem
                              onClick={() => setEditTimeType("DRIVE")}
                              value={"DRIVE"}
                            >
                              Fahrt
                            </MenuItem>
                            <MenuItem
                              onClick={() => setEditTimeType("BREAK")}
                              value={"BREAK"}
                            >
                              Pause
                            </MenuItem>
                            {tasks.map((task, index) => (
                              <MenuItem
                                key={index}
                                onClick={() =>
                                  setEditTimeType("CHECKINCHECKOUT")
                                }
                                value={task._id}
                              >
                                {task.customer}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : time.type !== "CHECKINCHECKOUT" ? (
                        <TimeType type={time.type} />
                      ) : (
                        <Customer type={"text"} taskId={time?.task?._id} />
                      )}

                      {editTimeId === time._id ? (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                          divider={<BsArrowRight />}
                        >
                          <TimePicker
                            label="start"
                            value={editStartTime || moment(time.starttime)}
                            onChange={(newValue) => setEditStartTime(newValue)}
                            renderInput={(params) => (
                              <TextField size={"small"} {...params} />
                            )}
                          />
                          {time.endtime ? (
                            <TimePicker
                              disabled={!time.endtime}
                              label="ende"
                              value={editEndTime || moment(time.endtime)}
                              onChange={(newValue) => setEditEndTime(newValue)}
                              renderInput={(params) => (
                                <TextField size={"small"} {...params} />
                              )}
                            />
                          ) : (
                            <Typography variant={"body1"}>
                              Checked In
                            </Typography>
                          )}
                        </Stack>
                      ) : (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                          divider={<BsArrowRight />}
                        >
                          <Typography variant={"body1"}>
                            {moment(time.starttime).format("HH:mm:ss")}
                          </Typography>
                          <Typography variant={"body1"}>
                            {time.endtime
                              ? moment(time.endtime).format("HH:mm:ss")
                              : "Checked In"}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      {!editTimeId && (
                        <Chip
                          variant="outlined"
                          label={
                            time.endtime
                              ? getDurationInHoursAndMinutesAndSeconds(
                                  time.starttime,
                                  time.endtime,
                                )
                              : "Checked In"
                          }
                        />
                      )}
                      {(me.me.role === "ADMIN" ||
                        me.me.role === "KOORDINATOR") && (
                        <Stack direction={"row"} spacing={1}>
                          {!editTimeId &&
                            time._id === timeArray.slice(-1)[0]._id &&
                            !moment()
                              .startOf("day")
                              .isAfter(
                                moment(timeArray.slice(-1)[0].endtime).startOf(
                                  "day",
                                ),
                              ) && (
                              <Fab
                                size={"small"}
                                color={"primary"}
                                onClick={() =>
                                  time.endtime
                                    ? continueTime(timeArray.slice(-1)[0]._id)
                                    : checkOut(timeArray.slice(-1)[0]._id)
                                }
                              >
                                {time.endtime ? (
                                  <BsFillPlayFill />
                                ) : (
                                  <BsFillPauseFill />
                                )}
                              </Fab>
                            )}

                          {!editTimeId && (
                            <Fab
                              size={"small"}
                              color={"secondary"}
                              onClick={() => {
                                window.confirm(
                                  "Möchtest du diese erfasste Zeit wirklich löschen?",
                                ) && removeTime(time._id);
                              }}
                            >
                              <BsFillTrash3Fill />
                            </Fab>
                          )}
                          {editTimeId === time._id ? (
                            <Stack direction={"row"} spacing={1}>
                              <Fab
                                size={"small"}
                                color={"primary"}
                                onClick={() => handleSaveEdit(time)}
                              >
                                <FaCheck />
                              </Fab>
                              <Fab
                                size={"small"}
                                color={"secondary"}
                                onClick={() => handleCancelEdit()}
                              >
                                <IoMdClose />
                              </Fab>
                            </Stack>
                          ) : (
                            <Fab
                              size={"small"}
                              color={"primary"}
                              onClick={() => setEditTimeId(time._id)}
                            >
                              <MdOutlineModeEdit />
                            </Fab>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

const EmployeeHistory = (props) => {
  const [archive, setArchive] = useState(0);
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [selectedEmployee, setSelectedEmployee] = useState(
    props.employee ? props.employee : null,
  );
  const {
    times,
    myTimes,
    archivedTimes,
    continueTime,
    removeTime,
    updateTime,
    checkOut,
    loading: timesLoading,
    error: timesError,
  } = useTimes();
  const { tasks, loading: tasksLoading, error: tasksError } = useTasks();
  const {
    excuses,
    loading: excusesLoading,
    error: excusesError,
  } = useExcuses();
  const { me, users, loading: usersLoading, error: usersError } = useUsers();

  const [timeArchive, setTimeArchive] = useState([]);

  if (timesLoading || usersLoading || tasksLoading || excusesLoading) return;
  if (timesError || usersError || tasksError || excusesError) return;

  const handleRenderTimeArchive = () => {
    archivedTimes().then((res) => {
      //console.log(res.data.getArchivedTimes);
      if (res.data?.getArchivedTimes) setTimeArchive(res.data.getArchivedTimes);
    });
  };

  let rawTimes =
    me.me.role === "ADMIN" || me.me.role === "KOORDINATOR"
      ? archive
        ? timeArchive
        : times
      : archive
        ? timeArchive.filter((time) => time.user._id === me.me._id)
        : myTimes;
  let sortedCustomers = rawTimes.reduce(function (o, cur) {
    if (!o) return o;
    //var occurs = o.find(ob => ob.customer._id === cur.task._id && ob.user._id === cur.user._id && moment(ob.day).startOf('day').isSame(moment(cur.starttime).startOf('day')));
    var occurs = [];
    if (tab === 0) {
      occurs = o.find(
        (ob) =>
          ob.user._id === cur.user._id &&
          moment(ob.day).isSame(moment(cur.starttime), "day"),
      );
    }
    if (tab === 1) {
      occurs = o.find(
        (ob) =>
          ob.user._id === cur.user._id &&
          moment(ob.day).isSame(moment(cur.starttime), "week"),
      );
    }
    if (tab === 2) {
      occurs = o.find(
        (ob) =>
          ob.user._id === cur.user._id &&
          moment(ob.day).isSame(moment(cur.starttime), "month"),
      );
    }

    if (occurs) {
      occurs.times.push(cur);
      if (cur.endtime) {
        if (cur.type === "CHECKINCHECKOUT") {
          occurs.worktime =
            occurs.worktime +
            getDurationInSecondsInt(cur.starttime, cur.endtime);
        }
        if (cur.type === "DRIVE") {
          occurs.drivetime =
            occurs.drivetime +
            getDurationInSecondsInt(cur.starttime, cur.endtime);
        }
        if (cur.type === "BREAK") {
          occurs.breaktime =
            occurs.breaktime +
            getDurationInSecondsInt(cur.starttime, cur.endtime);
        }
        if (cur.type === "HAENDLER") {
          occurs.haendlertime =
            occurs.haendlertime +
            getDurationInSecondsInt(cur.starttime, cur.endtime);
        }
      }
    } else {
      var obj = {
        day: cur.starttime,
        user: cur.user,
        worktime:
          cur.endtime && cur.type === "CHECKINCHECKOUT"
            ? getDurationInSecondsInt(cur.starttime, cur.endtime)
            : 0,
        drivetime:
          cur.endtime && cur.type === "DRIVE"
            ? getDurationInSecondsInt(cur.starttime, cur.endtime)
            : 0,
        breaktime:
          cur.endtime && cur.type === "BREAK"
            ? getDurationInSecondsInt(cur.starttime, cur.endtime)
            : 0,
        haendlertime:
          cur.endtime && cur.type === "HAENDLER"
            ? getDurationInSecondsInt(cur.starttime, cur.endtime)
            : 0,
        times: [cur],
      };
      o = o.concat(obj);
    }
    return o;
  }, []);

  const reduceWorkTimes = (times) => {
    let result = times.reduce(function (o, cur) {
      if (!o) return o;
      //var occurs = o.find(ob => ob.customer._id === cur.task._id && ob.user._id === cur.user._id && moment(ob.day).startOf('day').isSame(moment(cur.starttime).startOf('day')));
      var occurs = o.find((ob) => ob?.task?._id === cur?.task?._id);
      if (occurs) {
        if (cur.endtime) {
          if (cur.type === "CHECKINCHECKOUT") {
            occurs.worktime =
              occurs.worktime +
              getDurationInSecondsInt(cur.starttime, cur.endtime);
          }
          if (cur.type === "BREAK") {
            occurs.breaktime =
              occurs.breaktime +
              getDurationInSecondsInt(cur.starttime, cur.endtime);
          }
        }
      } else {
        var obj = {
          task: cur.worktime !== 0 ? cur.task : null,
          worktime:
            cur.endtime && cur.type === "CHECKINCHECKOUT"
              ? getDurationInSecondsInt(cur.starttime, cur.endtime)
              : 0,
          breaktime:
            cur.endtime && cur.type === "BREAK"
              ? getDurationInSecondsInt(cur.starttime, cur.endtime)
              : 0,
        };
        o = o.concat(obj);
      }
      return o;
    }, []);
    return result;
  };

  const handleSelectEmployee = (event, index) => {
    if (index) {
      setSelectedEmployee(users.find((x) => x._id === index._id));
      setPage(1);
    } else {
      setSelectedEmployee(null);
      setPage(1);
    }
  };

  const renderTimeName = () => {
    switch (tab) {
      case 0:
        return "Tag";
      case 1:
        return "KW + Jahr";
      case 2:
        return "Monat + Jahr";
      default:
        return "Fehler";
    }
  };

  const renderTimeFormat = (day) => {
    switch (tab) {
      case 0:
        return moment(day).format("LL");
      case 1:
        return moment(day).isoWeek() + " " + moment(day).year();
      case 2:
        return moment(day).format("MMMM") + " " + moment(day).year();
      default:
        return "Fehler";
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const renderCustomers = () => {
    let filteredCustomers = sortedCustomers
      .filter((data) =>
        selectedEmployee ? data.user._id === selectedEmployee._id : true,
      )
      .filter((data) =>
        props.date
          ? moment(data.day)
              .startOf("day")
              .isSame(moment(props.date).startOf("day"))
          : true,
      )
      .filter((data) => (props.user ? props.user._id === data.user._id : true))
      .sort(function (a, b) {
        return new Date(b.day) - new Date(a.day);
      });
    const indexOfLastPost = page * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = filteredCustomers.slice(
      indexOfFirstPost,
      indexOfLastPost,
    );
    return (
      <Stack direction={"column"} spacing={1}>
        {currentItems.map((data, index) => (
          <>
            {!props.minifiedView ? (
              <motion.div key={index} variants={itemElement}>
                <Paper key={index}>
                  <Stack direction={"column"} spacing={1} p={1}>
                    <Stack
                      pt={1}
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      px={1}
                    >
                      <User type={"text"} userId={data.user._id} />
                      <Stack direction={"column"}>
                        <Typography variant={"body2"}>
                          {renderTimeName()}
                        </Typography>
                        <Typography variant={"h7"}>
                          {renderTimeFormat(data.day)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction={"column"} spacing={1}>
                      <Accordion sx={{ boxShadow: "none" }}>
                        <Card sx={{ borderRadius: "0px", border: "none" }}>
                          <CardContent
                            sx={{ borderRadius: "0px", border: "none" }}
                          >
                            <Box pr={1}>
                              <AccordionSummary expandIcon={<IoIosArrowDown />}>
                                <Stack
                                  width={"100%"}
                                  direction={"column"}
                                  p={1}
                                  spacing={1}
                                  divider={<Divider />}
                                >
                                  <Stack
                                    pl={1}
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography variant={"h7"}>
                                      Gesamtzeit
                                    </Typography>

                                    <Chip
                                      variant="outlined"
                                      label={moment
                                        .duration(
                                          data.worktime +
                                            data.drivetime +
                                            data.haendlertime +
                                            data.breaktime,
                                          "seconds",
                                        )
                                        .format("hh:mm:ss", {
                                          trim: false,
                                        })}
                                    />
                                  </Stack>
                                  <Stack
                                    pl={1}
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography variant={"h7"}>
                                      Arbeitszeit
                                    </Typography>

                                    <Chip
                                      variant="outlined"
                                      label={moment
                                        .duration(
                                          data.worktime +
                                            data.drivetime +
                                            data.haendlertime,
                                          "seconds",
                                        )
                                        .format("hh:mm:ss", {
                                          trim: false,
                                        })}
                                    />
                                  </Stack>
                                  {tab === 0 &&
                                    checkForBreakTimes(
                                      moment
                                        .duration(
                                          data.worktime +
                                            data.drivetime +
                                            data.haendlertime,
                                          "seconds",
                                        )
                                        .asSeconds(),
                                      moment
                                        .duration(data.breaktime, "seconds")
                                        .asSeconds(),
                                    ) &&
                                    data.times.slice(-1)[0].endtime && (
                                      <Alert
                                        variant={"filled"}
                                        severity={"error"}
                                      >
                                        Pausenzeit nach §4 ArbZG nicht
                                        eingehalten!
                                      </Alert>
                                    )}
                                  {tab === 0 &&
                                    data.times.slice(-1)[0].type === "BREAK" &&
                                    data.times.slice(-1)[0].endtime && (
                                      <Alert
                                        variant={"filled"}
                                        severity={"error"}
                                      >
                                        Arbeitszeit nach §4 ArbZG nicht durch
                                        Pause unterbrochen. Pausen dürfen nicht
                                        am Anfang oder am Ende der Arbeitszeit
                                        genommen werden!
                                      </Alert>
                                    )}
                                </Stack>
                              </AccordionSummary>
                            </Box>
                          </CardContent>
                        </Card>
                        <AccordionDetails>
                          <Stack
                            direction={"column"}
                            p={1}
                            spacing={1}
                            divider={<Divider />}
                          >
                            {data.breaktime !== 0 && (
                              <Stack
                                px={1}
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <BsArrowReturnRight />
                                  <BiSolidCoffee />
                                  <Typography variant={"h7"}>
                                    Pausenzeit
                                  </Typography>
                                </Stack>
                                <Chip
                                  variant="outlined"
                                  label={moment
                                    .duration(data.breaktime, "seconds")
                                    .format("hh:mm:ss", {
                                      trim: false,
                                    })}
                                />
                              </Stack>
                            )}
                            {data.drivetime !== 0 && (
                              <Stack
                                px={1}
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <BsArrowReturnRight />
                                  <BsCarFrontFill />
                                  <Typography variant={"h7"}>
                                    Fahrtzeit
                                  </Typography>
                                </Stack>
                                <Chip
                                  variant="outlined"
                                  label={moment
                                    .duration(data.drivetime, "seconds")
                                    .format("hh:mm:ss", {
                                      trim: false,
                                    })}
                                />
                              </Stack>
                            )}
                            {data.haendlertime !== 0 && (
                              <Stack
                                px={1}
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <BsArrowReturnRight />
                                  <FaStore />
                                  <Typography variant={"h7"}>
                                    Händler
                                  </Typography>
                                </Stack>
                                <Chip
                                  variant="outlined"
                                  label={moment
                                    .duration(data.haendlertime, "seconds")
                                    .format("hh:mm:ss", {
                                      trim: false,
                                    })}
                                />
                              </Stack>
                            )}
                            {reduceWorkTimes(data.times)
                              .filter((time) => time.worktime > 0)
                              .map((time, index) => (
                                <Stack
                                  key={index}
                                  px={1}
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                  >
                                    <BsArrowReturnRight />
                                    <Customer
                                      type={"text"}
                                      taskId={
                                        time.task?._id ? time.task?._id : null
                                      }
                                    />
                                  </Stack>
                                  <Chip
                                    variant="outlined"
                                    label={moment
                                      .duration(time.worktime, "seconds")
                                      .format("hh:mm:ss", {
                                        trim: false,
                                      })}
                                  />
                                </Stack>
                              ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                    {tab === 1 && (
                      <TimeChart
                        section={"week"}
                        data={data}
                        chartID={index + tab}
                      />
                    )}
                    {tab === 2 && (
                      <TimeChart
                        section={"month"}
                        data={data}
                        chartID={index + tab}
                      />
                    )}
                    <RenderTimes
                      timeArray={data.times}
                      me={me}
                      continueTime={continueTime}
                      checkOut={checkOut}
                      removeTime={removeTime}
                      updateTime={updateTime}
                      tasks={tasks}
                    />

                    {tab === 2 && (
                      <EmployeeHourExport
                        employee={data.user}
                        times={data.times}
                        excuses={excuses.filter(
                          (excuse) => excuse.user._id === data.user._id,
                        )}
                        month={renderTimeFormat(data.day)}
                      />
                    )}
                  </Stack>
                </Paper>
              </motion.div>
            ) : (
              reduceWorkTimes(data.times)
                .filter((time) => time.worktime > 0)
                .filter((time) => time.task._id === props.task._id)
                .map((time) => (
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant={"h7"}>{time.task.customer}</Typography>
                    <Chip
                      variant="outlined"
                      label={moment
                        .duration(time.worktime - time.breaktime, "seconds")
                        .format("hh:mm:ss", {
                          trim: false,
                        })}
                    />
                  </Stack>
                ))
            )}
          </>
        ))}
        {!props.minifiedView && (
          <Paper>
            <Box p={1} display={"flex"} justifyContent={"center"}>
              <Pagination
                size="large"
                color={"primary"}
                count={Math.ceil(filteredCustomers.length / itemsPerPage)}
                page={page}
                onChange={handleChangePage}
              />
            </Box>
          </Paper>
        )}
      </Stack>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Paper>
        <Box p={1}>
          <Stack direction={"column"} spacing={1}>
            {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") && (
              <Stack direction={"row"} spacing={1}>
                <Autocomplete
                  id="employee"
                  onChange={handleSelectEmployee}
                  options={users}
                  fullWidth
                  getOptionLabel={(option) =>
                    option.prename + " " + option.surname
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Nach Mitarbeiter filtern"}
                      variant="filled"
                      size={"small"}
                    />
                  )}
                />
              </Stack>
            )}
            <Stack direction={"row"} spacing={1}>
              <Button
                disabled={tab === 0}
                onClick={() => {
                  setTab(0);
                  setPage(1);
                }}
                fullWidth
              >
                Tag
              </Button>
              <Button
                disabled={tab === 1}
                onClick={() => {
                  setTab(1);
                  setPage(1);
                }}
                fullWidth
              >
                Woche
              </Button>
              <Button
                disabled={tab === 2}
                onClick={() => {
                  setTab(2);
                  setPage(1);
                }}
                fullWidth
              >
                Monat
              </Button>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <Button
                disabled={!archive}
                onClick={() => {
                  setArchive(false);
                  setPage(1);
                }}
                fullWidth
              >
                Aktueller Monat
              </Button>
              <Button
                disabled={archive}
                onClick={() => {
                  setArchive(true);
                  setPage(1);
                  handleRenderTimeArchive();
                }}
                fullWidth
              >
                Archiv
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      <motion.div variants={containerElement} initial="hidden" animate="show">
        <AnimatePresence mode={"wait"}>{renderCustomers()}</AnimatePresence>
      </motion.div>
    </Stack>
  );
};

export default EmployeeHistory;
