import { gql } from "@apollo/client";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";
import { USER_ENTITY } from "../entities/userEntity";

export const GET_ALL_EXCUSES = gql`
  query getAllExcuses {
    getAllExcuses {
        ...ExcuseEntity
        user {
          ...UserEntity
        }
    }
  }
  ${EXCUSE_ENTITY},${USER_ENTITY}
`;
