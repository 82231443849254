import { gql } from "@apollo/client";
import { TASK_ENTITY } from "../entities/taskEntity";

export const UPDATE_TASK = gql`
  mutation updateTask($input: TaskInput!, $_id: String!) {
    updateTask(input: $input, _id: $_id) {
      ...TaskEntity
    }
  }
  ${TASK_ENTITY}
`;
