import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useEffect, useState } from "react";
import { useTasks } from "../../apollo/hooks/useTasks";
import { useNavigate, useParams } from "react-router-dom";
import FullpageLoader from "../common/FullPageLoader";
import Map from "../Map";
import "moment/locale/de";

const CreateTask = () => {
  const [assigned, setAssigned] = useState([]);
  const [adress, setAdress] = useState("");
  const [customer, setCustomer] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [plz, setPlz] = useState("");
  const [city, setCity] = useState("");

  const [radius, setRadius] = useState(0.2);
  const [long, setLong] = useState(null);
  const [lat, setLat] = useState(null);

  const routeParams = useParams();
  const router = useNavigate();
  const { users, loading: usersLoading, error: usersError } = useUsers();
  const {
    addTask,
    updateTask,
    getTaskById,
    loading: tasksLoading,
    error: tasksError,
  } = useTasks();

  useEffect(() => {
    if (routeParams.taskId && !usersLoading) {
      getTaskById(routeParams.taskId).then((val) => {
        //console.log(val.data.getTaskById.assigned.map(id => users?.find(user => user._id === id)))
        setAssigned(
          val.data.getTaskById.assigned.map((id) =>
            users?.find((user) => user._id === id),
          ),
        );
        setCustomer(val.data.getTaskById.customer);
        setStreet(val.data.getTaskById.street);
        setNumber(val.data.getTaskById.number);
        setPlz(val.data.getTaskById.plz);
        setCity(val.data.getTaskById.city);
        setLong(val.data.getTaskById.long);
        setLat(val.data.getTaskById.lat);
        setRadius(val.data.getTaskById.radius);
      });
    }
    // eslint-disable-next-line
  }, [usersLoading]);

  if (usersLoading || tasksLoading) return <FullpageLoader />;
  if (usersError || tasksError) return <FullpageLoader error />;

  const handleSelectAssigned = (event, index) => {
    var assigned = [];
    for (var i = 0; i < index.length; i++) {
      assigned.push(index[i]);
    }
    setAssigned(assigned);
  };

  const handleChangeLocation = (event) => {
    setLong(parseFloat(event.long));
    setLat(parseFloat(event.lat));
    if (event.location.features.length > 0) {
      //console.log(event.location.features[0])
      let location = {
        street: event.location.features[0].text,
        number: event.location.features[0].address,
        plz: event.location.features[0].context[0].text,
        ort: event.location.features[0].context[2].text,
        adress: event.location.features[0]?.place_name,
      };
      setStreet(location.street);
      setCity(location.ort);
      setPlz(location.plz);
      setNumber(location.number);
      setAdress(location.adress);
    } else {
      setStreet(null);
      setCity(null);
      setPlz(null);
      setNumber(null);
      setAdress("Unbekannte Adresse");
    }
  };

  const handleAddTask = () => {
    const payload = {
      assigned: assigned.map((user) => user._id),
      customer: customer,
      street: street,
      number: number,
      plz: plz,
      city: city,
      long: long,
      lat: lat,
      radius: parseFloat(radius),
    };
    addTask(payload).then((res) => {
      setAssigned([]);
      setCustomer("");
      setStreet("");
      setNumber("");
      setPlz("");
      setCity("");
      setLong(null);
      setLat(null);
      setRadius(0.2);
      router("/tasks");
    });
  };

  const handleUpdateTask = () => {
    const payload = {
      assigned: assigned.map((user) => user._id),
      customer: customer,
      street: street,
      number: number,
      plz: plz,
      city: city,
      long: long,
      lat: lat,
      radius: parseFloat(radius),
    };
    updateTask(payload, routeParams.taskId).then((res) => {
      setAssigned([]);
      setCustomer("");
      setStreet("");
      setNumber("");
      setPlz("");
      setCity("");
      setLong(null);
      setLat(null);
      setRadius(0.2);
      router("/tasks");
    });
  };

  const renderTaskEditor = () => {
    return (
      <Stack direction={"column"} spacing={1} position={"relative"}>
        <Paper>
          <Box p={1}>
            <Stack direction={"column"} spacing={1}>
              <Box px={1}>
                <Typography variant={"h7"}>Auftragsdetails</Typography>
              </Box>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={users}
                value={assigned && assigned}
                onChange={handleSelectAssigned}
                getOptionLabel={(option) =>
                  option?.prename + " " + option?.surname
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    label="Zuständige Mitarbeiter"
                  />
                )}
              />
              <TextField
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                variant={"filled"}
                label={"Kunde"}
              />
              <Card>
                <CardContent>
                  <Stack
                    direction={"column"}
                    px={3}
                    py={1}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Stack direction={"column"} textAlign={"center"}>
                      <Typography variant={"h7"}>{radius} km</Typography>
                      <Typography variant={"body1"}>Radius</Typography>
                    </Stack>
                    <Slider
                      value={radius}
                      defaultValue={0.2}
                      step={0.05}
                      min={0.1}
                      max={1}
                      onChange={(e) => setRadius(e.target.value)}
                    />
                  </Stack>
                </CardContent>
              </Card>
              <TextField
                value={adress}
                fullWidth
                variant={"filled"}
                label={"Adresse"}
              />
              {routeParams.taskId ? (
                long &&
                lat && (
                  <Map
                    createMode={false}
                    radius={radius}
                    long={long}
                    lat={lat}
                    onLocationChange={handleChangeLocation}
                  />
                )
              ) : (
                <Map
                  createMode={true}
                  radius={radius}
                  onLocationChange={handleChangeLocation}
                />
              )}
            </Stack>
          </Box>
        </Paper>
        <Box display={"flex"} justifyContent={"end"}>
          <Stack direction={"row"} spacing={1}>
            <Button
              disabled={
                assigned.length === 0 || !customer || !long || !lat || !radius
              }
              onClick={() =>
                routeParams.taskId ? handleUpdateTask() : handleAddTask()
              }
              variant={"contained"}
            >
              {routeParams.taskId ? "Aktualisieren" : "Speichern"}
            </Button>
          </Stack>
        </Box>
      </Stack>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {renderTaskEditor()}
    </Stack>
  );
};

export default CreateTask;
