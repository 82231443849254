import { gql } from "@apollo/client";
import { TASK_ENTITY } from "../entities/taskEntity";
import { TIME_ENTITY } from "../entities/timeEntity";

export const GET_ALL_TASKS = gql`
  query getAllTasks {
    getAllTasks {
        ...TaskEntity
        times {
          ...TimeEntity
        }
    }
  }
  ${TASK_ENTITY},${TIME_ENTITY}
`;
