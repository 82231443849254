import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import styles from "./home.module.css";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { containerElement, itemElement } from "../../stagger";
import { shadeColor } from "../../shade";
import FullpageLoader from "../common/FullPageLoader";
import { useTasks } from "../../apollo/hooks/useTasks";
import Time from "../Time";
import {
  CiClock2,
  CiLocationOn,
  CiLogout,
  CiMedicalCross,
  CiMug1,
  CiSettings,
} from "react-icons/ci";

const Home = () => {
  const router = useNavigate();
  const { me, users, loading: meLoading, error: meError } = useUsers();
  const { loading: tasksLoading, error: tasksError } = useTasks();

  if (meLoading || tasksLoading) return <FullpageLoader />;
  if (meError || tasksError) return <FullpageLoader error />;

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    window.location.reload();
  };

  const navButtons = [
    {
      category: "Zeiterfassung",
      component: <Time />,
      permissions: ["ADMIN", "MITARBEITER", "KOORDINATOR"],
      size: 12,
    },
    {
      title: "Erfasste Zeiten",
      icon: <CiClock2 />,
      color: "#40c935",
      action: () => router("/times"),
      permissions: ["ADMIN", "MITARBEITER", "KOORDINATOR"],
      category: "Zeiterfassung",
      size: 12,
    },
    {
      title: "Entschuldigungen",
      subtitle: "Urlaub, Krank, Kind krank etc.",
      icon: <CiMug1 />,
      color: "#f8a60e",
      action: () => router("/excuses"),
      permissions: ["ADMIN", "KOORDINATOR"],
      category: "Zeiterfassung",
      size: 12,
    },
    {
      title: "Aktuelle Aufträge",
      icon: <CiLocationOn />,
      color: "#1495e5",
      action: () => router("/tasks/current"),
      permissions: ["ADMIN", "KOORDINATOR"],
      category: "Aufträge",
      size: 6,
    },
    {
      title: "Neuer Auftrag",
      icon: <CiMedicalCross />,
      color: "#1495e5",
      action: () => router("/tasks/create"),
      permissions: ["ADMIN", "KOORDINATOR"],
      category: "Aufträge",
      size: 6,
    },
    {
      title: "Nutzer verwalten",
      subtitle: users.length + " Nutzer",
      icon: <CiSettings />,
      color: "#a6b0be",
      action: () => router("/users"),
      permissions: ["ADMIN", "KOORDINATOR"],
      category: "Sonstiges",
      size: 6,
    },
    {
      title: "Abmelden",
      subtitle: "Angemeldet als " + me.me.prename + " " + me.me.surname,
      icon: <CiLogout />,
      color: "#ff667f",
      action: () => handleLogout(),
      permissions: ["ADMIN", "KOORDINATOR", "MITARBEITER"],
      category: "Sonstiges",
      size: 6,
    },
  ];

  let sortedNavButtons = navButtons
    .filter((button) => button.permissions.includes(me.me.role))
    .reduce(function (o, cur) {
      var occurs = o.find((ob) => ob.category === cur.category);
      if (occurs) {
        occurs.buttons.push(cur);
      } else {
        var obj = {
          category: cur.category,
          buttons: [cur],
        };
        o = o.concat(obj);
      }

      return o;
    }, []);

  const renderCategories = () => {
    return sortedNavButtons.map((button, index) => (
      <Box className={styles.categoryWrapper} p={1}>
        <Stack key={index} direction={"column"} spacing={1}>
          <Box px={1}>
            <motion.div key={index} variants={itemElement}>
              <Typography color={"#60656e"} variant={"h6"}>
                {button.category}
              </Typography>
            </motion.div>
          </Box>
          <Box>
            <Grid container spacing={1}>
              {renderTasks(button.buttons)}
            </Grid>
          </Box>
        </Stack>
      </Box>
    ));
  };

  const renderTasks = (buttons) => {
    return buttons
      .filter((task) => !task.hide)
      .filter((task) => task.permissions.includes(me.me.role))
      .map((task, index) => (
        <Grid key={index} item xs={12} md={task.size}>
          <motion.div key={index} variants={itemElement}>
            <motion.div
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              transition={{
                type: "spring",
                stiffness: 100,
                mass: 0.1,
                damping: 10,
              }}
            >
              {task.component ? (
                task.component
              ) : (
                <Box
                  onClick={() => (task.action ? task.action() : "")}
                  className={styles.navButton}
                  display={"flex"}
                  alignItems={"center"}
                  p={0}
                >
                  <Stack
                    width={"100%"}
                    direction={"row"}
                    spacing={2}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Card sx={{ borderRadius: "5px 0px 0px 5px" }}>
                      <CardContent
                        sx={{
                          background: `linear-gradient(90deg, ${task.color}, ${shadeColor(task.color, 5)})`,
                        }}
                      >
                        <Box p={1}>
                          <Box
                            className={styles.iconWrapper}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Typography
                              style={{ lineHeight: "0px", zIndex: 3 }}
                              color={shadeColor(task.color, 150)}
                              variant={"h4"}
                            >
                              {task.icon}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Stack direction={"column"}>
                      <Typography variant={"h7"}>{task.title}</Typography>
                      {task.subtitle && (
                        <Typography variant={"body2"}>
                          {task.subtitle}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              )}
            </motion.div>
          </motion.div>
        </Grid>
      ));
  };

  return (
    <motion.div variants={containerElement} initial="hidden" animate="show">
      <AnimatePresence mode={"wait"}>
        <Stack direction={"column"} spacing={1}>
          {renderCategories()}
        </Stack>
      </AnimatePresence>
    </motion.div>
  );
};

export default Home;
