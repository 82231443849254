import { useNavigate } from "react-router-dom";
import { useTasks } from "../../apollo/hooks/useTasks";
import { useUsers } from "../../apollo/hooks/useUsers";
import {
  Autocomplete,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FullpageLoader from "../common/FullPageLoader";
import { useState } from "react";
import User from "../common/User";

const ViewTask = () => {
  const router = useNavigate();
  const {
    tasks,
    removeTask,
    loading: tasksLoading,
    error: tasksError,
  } = useTasks();
  const { users, loading: usersLoading, error: usersError } = useUsers();

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  if (usersLoading || tasksLoading)
    return <FullpageLoader position={"relative"} />;
  if (usersError || tasksError)
    return <FullpageLoader error position={"relative"} />;

  const handleSelectEmployee = (event, index) => {
    if (index) {
      setSelectedEmployee(users.find((x) => x._id === index._id));
    } else {
      setSelectedEmployee(null);
    }
  };

  const handleSelectCustomer = (event, index) => {
    if (index) {
      setSelectedCustomer(tasks.find((x) => x.customer === index.customer));
    } else {
      setSelectedCustomer(null);
    }
  };

  const renderTasks = () => {
    let taskArray = tasks;

    let filteredTasks = taskArray
      .filter((task) =>
        selectedCustomer ? task.customer === selectedCustomer.customer : true,
      )
      .filter((task) =>
        selectedEmployee
          ? task.assigned.some(
              (x) => selectedEmployee && x === selectedEmployee._id,
            )
          : true,
      );

    if (filteredTasks.length === 0) {
      return (
        <Box my={1}>
          <Paper>
            <Box p={2} textAlign={"center"}>
              <Typography variant={"body2"}>
                Keine Aufträge vorhanden.
              </Typography>
            </Box>
          </Paper>
        </Box>
      );
    }

    return filteredTasks.map((task, index) => (
      <Stack key={index} spacing={0} direction={"column"}>
        <Paper>
          <Stack direction={"column"} spacing={0}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"start"}
              justifyContent={"space-between"}
              p={2}
            >
              <Stack direction={"column"}>
                <Typography variant={"h6"}>{task.customer}</Typography>
                <Typography variant={"body2"}>
                  {task.street + " " + task.number}
                </Typography>
                <Typography variant={"body2"}>
                  {task.plz + " " + task.city}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Button onClick={() => router("/tasks/edit/" + task?._id)}>
                  Bearbeiten
                </Button>
                <Button
                  color={"secondary"}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Möchtest du diesen Auftrag wirklich löschen?",
                      )
                    ) {
                      removeTask(task?._id);
                    }
                  }}
                >
                  Löschen
                </Button>
              </Stack>
            </Stack>
            <Card sx={{ borderRadius: "0px" }}>
              <CardContent>
                <Box p={1}>
                  <Grid item xs={12}>
                    <Stack direction={"column"} spacing={1}>
                      <Stack
                        direction={"row"}
                        sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                      >
                        {task.assigned.map((user, index) => (
                          <User key={index} userId={user} />
                        ))}
                      </Stack>
                    </Stack>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Paper>
      </Stack>
    ));
  };

  return (
    <Stack direction={"column"} spacing={1}>
      <Paper>
        <Box p={1}>
          <Stack direction={"column"} spacing={1}>
            <Stack direction={"row"} spacing={1}>
              <Autocomplete
                id="customer"
                onChange={handleSelectCustomer}
                options={tasks}
                fullWidth
                getOptionLabel={(option) => option.customer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Nach Kunden filtern"}
                    variant="filled"
                    size={"small"}
                  />
                )}
              />
              <Autocomplete
                id="employee"
                onChange={handleSelectEmployee}
                options={users}
                fullWidth
                getOptionLabel={(option) =>
                  option.prename + " " + option.surname
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Nach Mitarbeiter filtern"}
                    variant="filled"
                    size={"small"}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Box>
      </Paper>
      {renderTasks()}
    </Stack>
  );
};

export default ViewTask;
