import { gql } from "@apollo/client";

import { USER_ENTITY } from "../entities/userEntity";
import { TIME_ENTITY } from "../entities/timeEntity";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";

export const ON_USERS_CHANGED = gql`
  subscription {
    onUsersChanged {
        ...UserEntity
        times {
          ...TimeEntity
        }
        excuses {
          ...ExcuseEntity
        }
    }
  }
  ${USER_ENTITY},${TIME_ENTITY},${EXCUSE_ENTITY}
`;
