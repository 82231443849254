import { gql } from "@apollo/client";
import { TIME_ENTITY } from "../entities/timeEntity";

export const CHECK_IN = gql`
  mutation checkIn($input: CheckInInput!) {
    checkIn(input: $input) {
      ...TimeEntity
    }
  }
  ${TIME_ENTITY}
`;
