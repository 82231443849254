import { gql } from "@apollo/client";
import { TASK_ENTITY } from "../entities/taskEntity";

export const CREATE_TASK = gql`
  mutation createTask($input: TaskInput!) {
    createTask(input: $input) {
      ...TaskEntity
    }
  }
  ${TASK_ENTITY}
`;
