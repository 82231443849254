import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { split } from "apollo-link";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { getToken } from "../src/helpers/jwtContents";

import theme from "../src/theme/theme";
import {
  Box,
  Container,
  CssBaseline,
  Stack,
  ThemeProvider,
} from "@mui/material";
import ContentRouter from "./views/ContentRouter";
import Navigation from "./views/Navigation";
import Authloader from "./auth/Authloader";
import styles from "./app.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { createUploadLink } from "apollo-upload-client";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import "moment/locale/de";

momentDurationFormatSetup(moment);

const App = () => {
  const [error] = React.useState("");
  const [loginRequired, setLoginRequired] = React.useState(false);

  const httpLink = new createUploadLink({
    uri: window.env.GRAPHQL_URL,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: getToken()
          ? "Bearer " + localStorage.getItem("jwt")
          : "",
      },
    });
    return forward(operation);
  });

  const wsLink = new WebSocketLink(
    new SubscriptionClient(window.env.WEBSOCKET_URL, {
      reconnect: true,
      connectionParams: {
        authToken: getToken() ? "Bearer " + localStorage.getItem("jwt") : "",
      },
    }),
  );

  const splittedHttpLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink,
  );

  const forceLogin = () => {
    setLoginRequired(true);
    if (localStorage.getItem("jwt")) {
      localStorage.removeItem("jwt");
      window.location.reload();
    }
  };

  const onErrorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          console.log(err);
          switch (err.extensions?.code) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            case "UNAUTHENTICATED":
              forceLogin();
              // Retry the request, returning the new observable
              return forward(operation);
            default:
              return;
          }
        }
      }

      // To retry on network errors, we recommend the RetryLink
      // instead of the onError link. This just logs the error.
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  );

  const link = concat(authMiddleware, onErrorLink.concat(splittedHttpLink));
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link,
    cache,
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider
            locale={moment.locale("de")}
            dateAdapter={AdapterMoment}
          >
            <Authloader error={error} loginRequired={loginRequired}>
              <Stack direction={"column"} className={styles.appContainer}>
                <Box className={styles.appWrapper} py={1}>
                  <Container maxWidth={"sm"}>
                    <Stack direction={"column"} spacing={1} mb={10}>
                      <ContentRouter />
                    </Stack>
                  </Container>
                </Box>
                <Box>
                  <Navigation />
                </Box>
              </Stack>
            </Authloader>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
