import { gql } from "@apollo/client";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";

export const CREATE_EXCUSE = gql`
  mutation createExcuse($input: ExcuseInput!) {
    createExcuse(input: $input) {
      ...ExcuseEntity
    }
  }
  ${EXCUSE_ENTITY}
`;
