import { gql } from "@apollo/client";
import { TIME_ENTITY } from "../entities/timeEntity";

export const CHECK_OUT = gql`
  mutation checkOut($_id: String!) {
    checkOut(_id: $_id) {
      ...TimeEntity
    }
  }
  ${TIME_ENTITY}
`;
