import { gql } from "@apollo/client";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";
import { USER_ENTITY } from "../entities/userEntity";

export const ON_EXCUSES_CHANGED = gql`
  subscription {
    onExcusesChanged {
        ...ExcuseEntity
        user {
          ...UserEntity
        }
    }
  }
  ${EXCUSE_ENTITY},${USER_ENTITY}
`;
