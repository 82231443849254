import { gql } from "@apollo/client";
import { TIME_ENTITY } from "../entities/timeEntity";

export const CONTINUE_TIME = gql`
  mutation continueTime($_id: String!) {
    continueTime(_id: $_id) {
      ...TimeEntity
    }
  }
  ${TIME_ENTITY}
`;
