// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time_navButton__zZV8x {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}
.time_iconWrapper__Rf\\+Tu {
  z-index: 0;
  position: relative;
  border-radius: 5px !important;
  width: 50px;
  height: 80px;
}
.time_checkInModalContent__C\\+vbt {
  height: 100%;
  overflow: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/views/Time/time.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,6BAA6B;EAC7B,iDAAiD;EACjD,eAAe;AACjB;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,6BAA6B;EAC7B,WAAW;EACX,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".navButton {\n  width: 100%;\n  background-color: #ffffff;\n  border-radius: 5px !important;\n  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n  cursor: pointer;\n}\n.iconWrapper {\n  z-index: 0;\n  position: relative;\n  border-radius: 5px !important;\n  width: 50px;\n  height: 80px;\n}\n.checkInModalContent {\n  height: 100%;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButton": `time_navButton__zZV8x`,
	"iconWrapper": `time_iconWrapper__Rf+Tu`,
	"checkInModalContent": `time_checkInModalContent__C+vbt`
};
export default ___CSS_LOADER_EXPORT___;
