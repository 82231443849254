import { createTheme } from "@mui/material";
import { setTransparency } from "../shade";
import { deDE } from "@mui/x-date-pickers/locales";

const primaryColor = "#027fc3";
const secondaryColor = "#d21f3c";

export default createTheme(
  {
    palette: {
      mode: "light",
      background: {
        default: "#ffffff",
      },
      primary: {
        main: primaryColor,
        contrastText: "#ffffff",
      },
      secondary: {
        main: secondaryColor,
        contrastText: "#ffffff",
      },
    },
    neutral: {
      color: "#da7720",
    },
    components: {
      MuiPaper: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            backgroundColor: "#ffffff",
            overflow: "hidden",
            borderRadius: "10px",
            backgroundImage: "none",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            border: "1px solid",
            borderColor: "#dedfe8",
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor: "#f3f3f3",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            border: "0px",
            borderRadius: "5px",
          },
        },
      },
      MuiCardContent: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            borderRadius: "5px",
            backgroundColor: "#f0f2f5", // Some CSS
            boxShadow: "none",
            padding: "0px",
            margin: "0px",
            "&:last-child": {
              padding: "0px",
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            "&.MuiMenu-list": {
              padding: "0px",
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "0.8rem",
            textTransform: "none",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          secondaryAction: {
            paddingRight: "80px",
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: "0px",
          },
          content: {
            "&.Mui-expanded": {
              minHeight: "0px",
              margin: "0px",
            },
            margin: "0px",
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "0px",
          },
          content: {
            margin: "0px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          rounded: {
            borderRadius: "5px",
          },
          root: {
            "&.Mui-expanded": {
              minHeight: "0px",
              marginTop: "8px",
            },
            "&:last-of-type": {
              borderRadius: "5px",
            },
            "&:first-of-type": {
              borderRadius: "5px",
            },
            borderRadius: "5px",
            "&:before": {
              opacity: "0",
            },
          },
        },
      },
      MuiListItemText: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            color: "#000000",
          },
          primary: {
            color: "#000000",
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          content: {
            "&.Mui-selected": {
              backgroundColor: "#f0f2f5",
            },
            paddingRight: "0px",
            height: "auto",
            borderRadius: "5px",
          },
        },
      },
      MuiButton: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            textTransform: "none", // Some CSS
            borderRadius: "5px",
            minHeight: "36.5px",
            "&.Mui-disabled": {
              background:
                "repeating-linear-gradient(-45deg,#f4f6fb,#f4f6fb 5px,#fbfcff 5px,#fbfcff 10px)",
            },
          },
          textPrimary: {
            backgroundColor: setTransparency(primaryColor, 5),
            "&:hover": {
              backgroundColor: setTransparency(primaryColor, 10),
            },
          },
          textSecondary: {
            backgroundColor: setTransparency(secondaryColor, 5),
            "&:hover": {
              backgroundColor: setTransparency(secondaryColor, 10),
            },
          },
          containedPrimary: {
            boxShadow: "none",
            backgroundColor: primaryColor,
            "&$disabled": {
              background: "#0d1b2f",
              color: "#afb6c5",
            },
          },
          containedSecondary: {
            boxShadow: "none",
            background: "#d21f3c",
            "&$disabled": {
              background: "#0d1b2f",
              color: "#afb6c5",
            },
          },
          outlinedPrimary: {
            borderWidth: "1px",
            "&:hover": {
              borderWidth: "1px",
            },
          },
          outlinedSecondary: {
            borderWidth: "1px",
            "&:hover": {
              borderWidth: "1px",
            },
          },
          contained: {
            boxShadow: "none",
            backgroundColor: "#0d1b2f",
            color: "#ffffff",
            "&$disabled": {
              backgroundColor: "#0d1b2f",
              color: "#afb6c5",
            },
            "&:hover": {
              backgroundColor: "#1a2035",
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            "&$selected": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          label: {
            textTransform: "none",
          },
          root: {
            border: 0,
            textTransform: "none",
            "&$selected": {
              backgroundColor: primaryColor,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          info: {
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#4d6990",
            },
          },
          primary: {
            color: primaryColor,
            backgroundColor: setTransparency(primaryColor, 5),
            "&:hover": {
              backgroundColor: setTransparency(primaryColor, 10),
            },
          },
          secondary: {
            color: secondaryColor,
            backgroundColor: setTransparency(secondaryColor, 5),
            "&:hover": {
              backgroundColor: setTransparency(secondaryColor, 10),
            },
          },
          root: {
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          },
          sizeMedium: {
            fontSize: "1.5rem",
          },
          sizeSmall: {
            height: "36px",
            width: "36px",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-of-type(even)": {
              backgroundColor: "#f0f2f5",
            },
            "&:last-child": {
              borderBottom: "none",
            },
          },
        },
      },
      MuiInputAdornment: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            backgroundColor: "transparent", // Some CSS
          },
        },
      },
      MuiTab: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            textTransform: "none",
          },
        },
      },
      MuiChip: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            borderRadius: "5px",
          },
          colorDefault: {
            backgroundColor: "#e7e9ee",
          },
        },
      },
      MuiTypography: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          root: {
            // Name of the rule
            color: "#000000",
          },
          h1: {
            fontWeight: 700,
          },
          h2: {
            fontWeight: 700,
          },
          h3: {
            fontWeight: 700,
          },
          h4: {
            fontWeight: 700,
          },
          h5: {
            fontWeight: 700,
          },
          h6: {
            fontWeight: 500,
          },
          h7: {
            fontWeight: 500,
            lineHeight: "1.1rem",
          },
          body1: {
            fontSize: "0.9rem",
            lineHeight: "1.1rem",
          },
          body2: {
            fontSize: "0.8rem",
            lineHeight: "1.1rem",
            color: "#9399ad",
          },
          subtitle2: {
            fontSize: "0.75rem",
          },
        },
      },
      MuiTimeline: {
        styleOverrides: {
          root: {
            padding: "0px",
            margin: "0px",
          },
        },
      },
      MuiTimelineContent: {
        styleOverrides: {
          root: {
            padding: "8px 0px 0px 16px",
          },
        },
      },
      MuiTooltip: {
        // Name of the component ⚛️ / style sheet
        styleOverrides: {
          tooltip: {
            // Name of the rule
            color: "#afb6c5", // Some CSS
            backgroundColor: "rgba(0,0,0,0.0)",
            opacity: "1",
          },
          popper: {
            opacity: "1",
          },
        },
      },
      MuiExpansionPanelSummary: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
            backgroundColor: "#0d1b2f",
            borderBottom: "3px solid #2f80ed",
          },
        },
      },
      MuiExpansionPanel: {
        styleOverrides: {
          root: {
            "&:before": {
              display: "none",
            },
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "#0d1b2f",
            height: "20px",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            border: "none",
            background: "transparent",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "24px",
            whiteSpace: "pre-wrap",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          markLabel: {
            filter: "grayscale(100%)",
          },
          markLabelActive: {
            filter: "grayscale(0%)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#e7e9ee",
            borderRadius: "5px",
          },
          notchedOutline: {
            border: "none",
          },
          multiline: {
            borderRadius: "5px",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#f0f2f5",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "#e7e9ee",
            },
          },
          multiline: {
            borderRadius: "5px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.MuiFilledInput-root:after": {
              borderBottom: 0,
            },
            "&.MuiFilledInput-root:before": {
              display: "none",
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.MuiChip-root": {
              height: "25px",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      useNextVariants: true,
    },
  },
  deDE,
);
