import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { ON_TASKS_CHANGED } from "../subscriptions/onTasksChanged";
import { CREATE_TASK } from "../mutations/createTask";
import { UPDATE_TASK } from "../mutations/updateTask";
import { REMOVE_TASK } from "../mutations/removeTask";
import { GET_TASK_BY_ID } from "../queries/getTaskById";
import { GET_ALL_TASKS } from "../queries/getAllTasks";
import { useUsers } from "./useUsers";

export const useTasks = () => {
  const { me } = useUsers();
  const fetchQuery = GET_ALL_TASKS;

  const {
    data: dataQuery,
    loading: loadingQuery,
    error: errorQuery,
    refetch: refetchQuery,
  } = useQuery(fetchQuery, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  const { data: allTasksSubData } = useSubscription(ON_TASKS_CHANGED, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  let taskData = allTasksSubData
    ? allTasksSubData?.onTasksChanged
    : dataQuery
      ? dataQuery?.getAllTasks
      : [];

  //console.log("TaskQuery", allTasksSubData?.onTasksChanged, dataQuery?.getAllTasks)

  const [add, { error: errorAdd }] = useMutation(CREATE_TASK, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("task created!");
    },
  });

  const [update, { error: errorUpdate }] = useMutation(UPDATE_TASK, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("task updated!");
    },
  });

  const [remove, { error: errorRemove }] = useMutation(REMOVE_TASK, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("task removed!");
    },
  });

  const [getById] = useLazyQuery(GET_TASK_BY_ID, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      //refetchQuery()
      console.log("task found!");
    },
  });

  return {
    tasks: taskData,
    myTasks: taskData?.filter((task) => task.assigned.includes(me?.me._id)),
    loading: loadingQuery,
    error: errorQuery || errorAdd || errorRemove || errorUpdate,
    addTask: (input) =>
      add({
        variables: {
          input: input,
        },
      }),
    updateTask: (input, id) =>
      update({
        variables: {
          input: input,
          _id: id,
        },
      }),
    removeTask: (id) =>
      remove({
        variables: {
          _id: id,
        },
      }),
    getTaskById: (id) =>
      getById({
        variables: {
          _id: id,
        },
      }),
  };
};
