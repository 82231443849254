import { gql } from "@apollo/client";
import { TASK_ENTITY } from "../entities/taskEntity";
import { TIME_ENTITY } from "../entities/timeEntity";

export const ON_TASKS_CHANGED = gql`
  subscription {
    onTasksChanged {
        ...TaskEntity
        times {
          ...TimeEntity
        }
    }
  }
  ${TASK_ENTITY},${TIME_ENTITY}
`;
