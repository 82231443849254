import { gql } from "@apollo/client";

export const EXCUSE_ENTITY = gql`
  fragment ExcuseEntity on ExcuseEntity {
    _id
    reason
    startdate
    enddate
    created_at
    updated_at
  }
`;
