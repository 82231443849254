import { gql } from "@apollo/client";
import { TIME_ENTITY } from "../entities/timeEntity";

export const UPDATE_TIME = gql`
  mutation updateTime($input: EditTimeInput!, $_id: String!) {
    updateTime(input: $input, _id: $_id) {
      ...TimeEntity
    }
  }
  ${TIME_ENTITY}
`;
