import {
  Autocomplete,
  Box,
  Button,
  Fab,
  Grid,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import FullpageLoader from "../common/FullPageLoader";
import { useExcuses } from "../../apollo/hooks/useExcuses";
import moment from "moment";
import User from "../common/User";
import { BsFillTrash3Fill } from "react-icons/bs";
import { useUsers } from "../../apollo/hooks/useUsers";
import { DatePicker } from "@mui/x-date-pickers";
import { MdOutlineModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const CreateExcuse = ({ addExcuse, users }) => {
  const [reason, setReason] = useState("");
  const [startdate, setStartdate] = useState(moment().startOf("day"));
  const [enddate, setEnddate] = useState(moment().startOf("day"));
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleSelectEmployee = (event, index) => {
    if (index) {
      setSelectedEmployee(users.find((x) => x._id === index._id));
    } else {
      setSelectedEmployee(null);
    }
  };

  const handleAddExcuse = () => {
    const payload = {
      user: selectedEmployee._id.toString(),
      reason: reason,
      startdate: moment(startdate).startOf("day"),
      enddate: moment(enddate).startOf("day"),
    };
    addExcuse(payload).then((res) => {
      setSelectedEmployee(null);
      setReason("");
      setStartdate(moment().startOf("day"));
      setEnddate(moment().startOf("day"));
    });
  };

  return (
    <Paper>
      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="employee"
              onChange={handleSelectEmployee}
              options={users}
              fullWidth
              getOptionLabel={(option) => option.prename + " " + option.surname}
              renderInput={(params) => (
                <TextField {...params} label={"Mitarbeiter"} variant="filled" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label={"Grund"}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <DatePicker
              label="von"
              value={startdate}
              onChange={(newValue) => setStartdate(newValue)}
              renderInput={(params) => (
                <TextField fullWidth size={"small"} {...params} />
              )}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <DatePicker
              label="bis"
              value={enddate}
              onChange={(newValue) => setEnddate(newValue)}
              renderInput={(params) => (
                <TextField fullWidth size={"small"} {...params} />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              fullWidth
              disabled={!selectedEmployee || !startdate || !enddate || !reason}
              color={"primary"}
              onClick={() => handleAddExcuse()}
            >
              Hinzufügen
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const RenderList = ({
  excuses,
  removeExcuse,
  updateExcuse,
  selectedEmployee,
}) => {
  const [editExcuseId, setEditExcuseId] = useState(null);
  const [reason, setReason] = useState("");
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);

  const handleSaveEdit = (excuse) => {
    const payload = {
      user: excuse.user._id.toString(),
      reason: reason || excuse.reason,
      startdate: startdate || excuse.startdate,
      enddate: enddate || excuse.enddate,
    };
    updateExcuse(payload, excuse._id).then(() => {
      setEditExcuseId(null);
      setReason("");
      setStartdate(null);
      setEnddate(null);
    });
  };

  const handleCancelEdit = () => {
    setEditExcuseId(null);
    setReason("");
    setStartdate(null);
    setEnddate(null);
  };

  return (
    <Table size={"small"}>
      <TableHead>
        <TableCell>Mitarbeiter</TableCell>
        <TableCell>von-bis</TableCell>
        <TableCell>Grund</TableCell>
        <TableCell>Aktionen</TableCell>
      </TableHead>
      <TableBody>
        {excuses.map((excuse, index) => (
          <TableRow key={index}>
            <TableCell>
              <User userId={excuse.user._id} />
            </TableCell>
            <TableCell>
              {editExcuseId === excuse._id ? (
                <Stack direction={"column"} spacing={1} sx={{ minWidth: 150 }}>
                  <DatePicker
                    label="von"
                    value={startdate || excuse.startdate}
                    onChange={(newValue) => setStartdate(newValue)}
                    renderInput={(params) => (
                      <TextField fullWidth size={"small"} {...params} />
                    )}
                  />
                  <DatePicker
                    label="bis"
                    value={enddate || excuse.enddate}
                    onChange={(newValue) => setEnddate(newValue)}
                    renderInput={(params) => (
                      <TextField fullWidth size={"small"} {...params} />
                    )}
                  />
                </Stack>
              ) : (
                <Box>
                  {moment(excuse.startdate).format("L") +
                    " - " +
                    moment(excuse.enddate).format("L")}
                </Box>
              )}
            </TableCell>
            <TableCell>
              {editExcuseId === excuse._id ? (
                <TextField
                  sx={{ minWidth: 150 }}
                  fullWidth
                  value={reason || excuse.reason}
                  onChange={(e) => setReason(e.target.value)}
                  label={"Grund"}
                />
              ) : (
                excuse.reason
              )}
            </TableCell>
            <TableCell>
              {editExcuseId === excuse._id ? (
                <Stack direction={"row"} spacing={1}>
                  <Fab
                    size={"small"}
                    color={"primary"}
                    onClick={() => handleSaveEdit(excuse)}
                  >
                    <FaCheck />
                  </Fab>
                  <Fab
                    size={"small"}
                    color={"secondary"}
                    onClick={() => handleCancelEdit()}
                  >
                    <IoMdClose />
                  </Fab>
                </Stack>
              ) : (
                <Stack direction={"row"} spacing={1}>
                  <Fab
                    size={"small"}
                    color={"primary"}
                    onClick={() => setEditExcuseId(excuse._id)}
                  >
                    <MdOutlineModeEdit />
                  </Fab>
                  <Fab
                    size={"small"}
                    color={"secondary"}
                    onClick={() => {
                      window.confirm(
                        "Möchtest du diese Entschuldigung wirklich löschen?",
                      ) && removeExcuse(excuse._id);
                    }}
                  >
                    <BsFillTrash3Fill />
                  </Fab>
                </Stack>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const ListExcuses = ({ excuses, removeExcuse, updateExcuse, users }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleSelectEmployee = (event, index) => {
    if (index) {
      setSelectedEmployee(users.find((x) => x._id === index._id));
      setPage(1);
    } else {
      setSelectedEmployee(null);
      setPage(1);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  let filteredExcuses = excuses.filter((data) =>
    selectedEmployee ? data.user._id === selectedEmployee._id : true,
  );

  const indexOfLastPost = page * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = filteredExcuses.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Stack spacing={1} direction={"column"}>
      <Paper>
        <Box p={1}>
          <Autocomplete
            id="employee"
            onChange={handleSelectEmployee}
            options={users}
            fullWidth
            getOptionLabel={(option) => option.prename + " " + option.surname}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Nach Mitarbeiter filtern"}
                variant="filled"
                size={"small"}
              />
            )}
          />
        </Box>
      </Paper>
      <Paper style={{ overflowX: "auto" }}>
        <RenderList
          excuses={currentItems}
          removeExcuse={removeExcuse}
          updateExcuse={updateExcuse}
          selectedEmployee={selectedEmployee}
        />
      </Paper>
      <Paper>
        <Box p={1} display={"flex"} justifyContent={"center"}>
          <Pagination
            onChange={handleChangePage}
            count={Math.ceil(filteredExcuses.length / itemsPerPage)}
            page={page}
            size="large"
            color={"primary"}
          />
        </Box>
      </Paper>
    </Stack>
  );
};

const Excuses = () => {
  const {
    excuses,
    removeExcuse,
    addExcuse,
    updateExcuse,
    loading: excusesLoading,
    error: excusesError,
  } = useExcuses();

  const { users, loading: usersLoading, error: usersError } = useUsers();

  if (excusesLoading || usersLoading)
    return <FullpageLoader position={"relative"} />;
  if (excusesError || usersError)
    return <FullpageLoader error position={"relative"} />;

  return (
    <Stack direction={"column"} spacing={1}>
      <CreateExcuse addExcuse={addExcuse} users={users} />
      <ListExcuses
        excuses={excuses}
        removeExcuse={removeExcuse}
        updateExcuse={updateExcuse}
        users={users}
      />
    </Stack>
  );
};

export default Excuses;
