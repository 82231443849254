import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useUsers } from "../../apollo/hooks/useUsers";
import { useState } from "react";
import FullpageLoader from "../common/FullPageLoader";

const Users = () => {
  const [prename, setPrename] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("BAULEITER");

  const [editprename, setEditPrename] = useState("");
  const [editsurname, setEditSurname] = useState("");
  const [editusername, setEditUsername] = useState("");
  const [editrole, setEditRole] = useState("");

  const [editIndex, setEditIndex] = useState(null);

  const {
    me,
    users,
    addUser,
    removeUser,
    updateUser,
    userRoles,
    loading: usersLoading,
    error: usersError,
  } = useUsers();
  if (usersLoading || usersError) return;

  if (usersLoading) return <FullpageLoader position={"relative"} />;
  if (usersError) return <FullpageLoader error position={"relative"} />;

  const handleSetEditUser = (index, user) => {
    setEditIndex(index);
    setEditPrename(user.prename);
    setEditSurname(user.surname);
    setEditUsername(user.username);
    setEditRole(user.role);
  };

  const handleRemoveUser = (id) => {
    removeUser(id);
  };

  const handleClear = () => {
    setPrename("");
    setSurname("");
    setUsername("");
    setPassword("");
    setRole("BAULEITER");
    setEditIndex(null);
  };

  const handleAddUser = () => {
    const payload = {
      role: role,
      prename: prename,
      surname: surname,
      username: username,
      password: password,
    };
    addUser(payload).then(() => {
      handleClear();
    });
  };

  const handleEditUser = (id) => {
    const payload = {
      role: editrole,
      prename: editprename,
      surname: editsurname,
      username: editusername,
    };
    updateUser(payload, id).then(() => {
      setEditIndex(null);
    });
  };

  function stringToColor(string) {
    let hash = 4588328621668634;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const renderUsers = () => {
    return users.map((user, index) => (
      <Box>
        {editIndex !== index ? (
          <Paper key={index}>
            <Stack
              direction={"row"}
              spacing={1}
              p={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <Avatar {...stringAvatar(user.prename + " " + user.surname)} />
                <Stack direction={"column"}>
                  <Typography variant={"h7"}>
                    {user.prename} {user.surname}
                  </Typography>
                  <Typography variant={"body2"}>
                    {user.username} - {user.role}
                  </Typography>
                </Stack>
              </Stack>
              {me.me.role === "ADMIN" && (
                <Stack direction={"row"} spacing={1} justifyContent={"end"}>
                  <Button
                    onClick={() => handleSetEditUser(index, user)}
                    color={"primary"}
                  >
                    Bearbeiten
                  </Button>
                  <Button
                    disabled={me.me._id === user._id}
                    onClick={() => handleRemoveUser(user._id)}
                    color={"secondary"}
                  >
                    Löschen
                  </Button>
                </Stack>
              )}
            </Stack>
          </Paper>
        ) : (
          <Stack direction={"column"} spacing={1}>
            <Paper key={index}>
              <Box p={1}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      size={"small"}
                      variant={"filled"}
                      value={editprename}
                      onChange={(e) => setEditPrename(e.target.value)}
                      fullWidth
                      label={"Vorname"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size={"small"}
                      variant={"filled"}
                      value={editsurname}
                      onChange={(e) => setEditSurname(e.target.value)}
                      fullWidth
                      label={"Nachname"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size={"small"}
                      variant={"filled"}
                      value={editusername}
                      onChange={(e) => setEditUsername(e.target.value)}
                      fullWidth
                      label={"Nutzername"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl size={"small"} fullWidth variant="filled">
                      <InputLabel>Nutzerrolle</InputLabel>
                      <Select
                        value={editrole}
                        onChange={(e) => setEditRole(e.target.value)}
                      >
                        {userRoles.getUserRoles.map((role, index) => (
                          <MenuItem key={index} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      display={"flex"}
                      justifyContent={"end"}
                    >
                      <Button onClick={() => handleClear()} color={"secondary"}>
                        Abbrechen
                      </Button>
                      <Button onClick={() => handleEditUser(user._id)}>
                        Speichern
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Stack>
        )}
      </Box>
    ));
  };

  const renderAddUser = () => {
    return (
      <Stack direction={"column"} spacing={1}>
        <Paper>
          <Box p={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  size={"small"}
                  variant={"filled"}
                  value={prename}
                  onChange={(e) => setPrename(e.target.value)}
                  fullWidth
                  label={"Vorname"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={"small"}
                  variant={"filled"}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  fullWidth
                  label={"Nachname"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={"small"}
                  variant={"filled"}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  fullWidth
                  label={"Nutzername"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size={"small"}
                  variant={"filled"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  label={"Passwort"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl size={"small"} fullWidth variant="filled">
                  <InputLabel>Nutzerrolle</InputLabel>
                  <Select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    {userRoles.getUserRoles.map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  spacing={1}
                  display={"flex"}
                  justifyContent={"end"}
                >
                  <Button
                    disabled={
                      !prename || !surname || !role || !password || !username
                    }
                    onClick={() => handleAddUser()}
                  >
                    Nutzer hinzufügen
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Stack>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {renderUsers()}
      {renderAddUser()}
    </Stack>
  );
};

export default Users;
