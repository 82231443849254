import { gql } from "@apollo/client";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";
import { USER_ENTITY } from "../entities/userEntity";

export const GET_EXCUSE_BY_ID = gql`
  query getExcuseById($_id: String!){
    getExcuseById(_id: $_id) {
        ...ExcuseEntity
        user {
          ...UserEntity
        }
    }
  }
  ${EXCUSE_ENTITY},${USER_ENTITY}
`;
