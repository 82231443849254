import React from "react";

import Login from "../views/common/Login/Login";
import { useUsers } from "../apollo/hooks/useUsers";
import FullPageLoader from "../views/common/FullPageLoader";

const AuthLoader = (props) => {
  const { me, loading } = useUsers();
  if (loading) return <FullPageLoader position={"absolute"} />;
  if (!me || props.loginRequired) return <Login />;
  return props.children;
};

export default AuthLoader;
