import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../Home";
import Tasks from "../Tasks";
import Users from "../Users";
import CreateTask from "../Tasks/CreateTask";
import PageTransition from "../../PageTransition";
import { AnimatePresence } from "framer-motion";
import TimeHistory from "../Time/History/TimeHistory";
import Excuses from "../Excuses";

const ContentRouter = () => {
  const location = useLocation();
  return (
    <AnimatePresence
      mode={"wait"}
      initial={false}
      onExitComplete={() => window.scrollTo(0, 0)}
    >
      <Routes location={location} key={location.pathname}>
        <Route
          index
          element={
            <PageTransition>
              <Home />
            </PageTransition>
          }
        />
        <Route path="excuses">
          <Route
            index
            element={
              <PageTransition>
                <Excuses />
              </PageTransition>
            }
          />
        </Route>
        <Route path="tasks">
          <Route
            index
            element={
              <PageTransition>
                <Tasks />
              </PageTransition>
            }
          />
          <Route
            path="create"
            element={
              <PageTransition>
                <CreateTask />
              </PageTransition>
            }
          />
          <Route
            path="current"
            element={
              <PageTransition>
                <Tasks />
              </PageTransition>
            }
          />
          <Route
            path="edit/:taskId"
            element={
              <PageTransition>
                <CreateTask />
              </PageTransition>
            }
          />
        </Route>
        <Route path="times">
          <Route
            index
            element={
              <PageTransition>
                <TimeHistory />
              </PageTransition>
            }
          />
          <Route
            path=":section"
            element={
              <PageTransition>
                <TimeHistory />
              </PageTransition>
            }
          />
        </Route>
        <Route path="users">
          <Route
            index
            element={
              <PageTransition>
                <Users />
              </PageTransition>
            }
          />
          <Route
            path="create"
            element={
              <PageTransition>
                <Users />
              </PageTransition>
            }
          />
          <Route
            path=":id"
            element={
              <PageTransition>
                <Users />
              </PageTransition>
            }
          />
          <Route
            path=":id/edit"
            element={
              <PageTransition>
                <Users />
              </PageTransition>
            }
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default ContentRouter;
