import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { GET_ALL_EXCUSES } from "../queries/getAllExcuses";
import { ON_EXCUSES_CHANGED } from "../subscriptions/onExcusesChanged";
import { CREATE_EXCUSE } from "../mutations/createExcuse";
import { UPDATE_EXCUSE } from "../mutations/updateExcuse";
import { REMOVE_EXCUSE } from "../mutations/removeExcuse";
import { GET_EXCUSE_BY_ID } from "../queries/getExcuseById";

export const useExcuses = () => {
  const fetchQuery = GET_ALL_EXCUSES;

  const {
    data: dataQuery,
    loading: loadingQuery,
    error: errorQuery,
    refetch: refetchQuery,
  } = useQuery(fetchQuery, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  const { data: allExcusesSubData } = useSubscription(ON_EXCUSES_CHANGED, {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  });

  let excuseData = allExcusesSubData
    ? allExcusesSubData?.onExcusesChanged
    : dataQuery
      ? dataQuery?.getAllExcuses
      : [];

  //console.log("TaskQuery", allTasksSubData?.onTasksChanged, dataQuery?.getAllTasks)

  const [add, { error: errorAdd }] = useMutation(CREATE_EXCUSE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("excuse created!");
    },
  });

  const [update, { error: errorUpdate }] = useMutation(UPDATE_EXCUSE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("excuse updated!");
    },
  });

  const [remove, { error: errorRemove }] = useMutation(REMOVE_EXCUSE, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      refetchQuery();
      console.log("excuse removed!");
    },
  });

  const [getById] = useLazyQuery(GET_EXCUSE_BY_ID, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: (res) => {
      if (!res) return;
      //refetchQuery()
      console.log("excuse found!");
    },
  });

  return {
    excuses: excuseData,
    loading: loadingQuery,
    error: errorQuery || errorAdd || errorRemove || errorUpdate,
    addExcuse: (input) =>
      add({
        variables: {
          input: input,
        },
      }),
    updateExcuse: (input, id) =>
      update({
        variables: {
          input: input,
          _id: id,
        },
      }),
    removeExcuse: (id) =>
      remove({
        variables: {
          _id: id,
        },
      }),
    getExcuseById: (id) =>
      getById({
        variables: {
          _id: id,
        },
      }),
  };
};
