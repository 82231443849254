import { useTimes } from "../../../apollo/hooks/useTimes";
import { useUsers } from "../../../apollo/hooks/useUsers";
import moment from "moment/moment";
import {
  getDurationInHoursAndMinutesAndSeconds,
  getDurationInSecondsInt,
} from "../../../dateFunctions";
import { useState } from "react";
import { useTasks } from "../../../apollo/hooks/useTasks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { containerElement, itemElement } from "../../../stagger";
import User from "../../common/User";
import { IoIosArrowDown } from "react-icons/io";
import { BsArrowReturnRight, BsArrowRight } from "react-icons/bs";
import Customer from "../../common/Customer";
import TaskHourExport from "./TaskHourExport";

const CustomerHistory = () => {
  const [month, setMonth] = useState(
    moment().startOf("month").format("MMMM YYYY"),
  );
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [selectedTask, setSelectedTask] = useState(null);
  const { times, loading: timesLoading, error: timesError } = useTimes();
  const { me, loading: usersLoading, error: usersError } = useUsers();
  const { tasks, loading: tasksLoading, error: tasksError } = useTasks();

  if (timesLoading || usersLoading || tasksLoading) return;
  if (timesError || usersError || tasksError) return;

  let sortedTasks = times.reduce(function (o, cur) {
    if (!o) return o;
    var occurs = [];

    if (tab === 0) {
      occurs = o.find(
        (ob) =>
          ob.task &&
          cur.task &&
          ob.task._id === cur.task._id &&
          moment(ob.day).isSame(moment(cur.starttime), "day"),
      );
    }
    if (tab === 1) {
      occurs = o.find(
        (ob) =>
          ob.task &&
          cur.task &&
          ob.task._id === cur.task._id &&
          moment(ob.day).isSame(moment(cur.starttime), "week"),
      );
    }
    if (tab === 2) {
      occurs = o.find(
        (ob) =>
          ob.task &&
          cur.task &&
          ob.task._id === cur.task._id &&
          moment(ob.day).isSame(moment(cur.starttime), "month"),
      );
    }

    if (occurs) {
      occurs.times.push(cur);
      if (cur.endtime) {
        if (cur.type === "CHECKINCHECKOUT") {
          occurs.worktime =
            occurs.worktime +
            getDurationInSecondsInt(cur.starttime, cur.endtime);
        }
      }
    } else {
      if (cur.task) {
        var obj = {
          day: cur.starttime,
          task: cur.task,
          worktime:
            cur.endtime && cur.type === "CHECKINCHECKOUT"
              ? getDurationInSecondsInt(cur.starttime, cur.endtime)
              : 0,
          times: [cur],
        };
        o = o.concat(obj);
      }
    }
    return o;
  }, []);

  const reduceWorkTimes = (times) => {
    let result = times.reduce(function (o, cur) {
      if (!o || !cur.user) return o;
      //var occurs = o.find(ob => ob.customer._id === cur.task._id && ob.user._id === cur.user._id && moment(ob.day).startOf('day').isSame(moment(cur.starttime).startOf('day')));
      var occurs = o.find((ob) => ob?.user?._id === cur?.user?._id);
      if (occurs) {
        if (cur.endtime) {
          if (cur.type === "CHECKINCHECKOUT") {
            occurs.worktime =
              occurs.worktime +
              getDurationInSecondsInt(cur.starttime, cur.endtime);
          }
        }
      } else {
        var obj = {
          user: cur.user,
          worktime:
            cur.endtime && cur.type === "CHECKINCHECKOUT"
              ? getDurationInSecondsInt(cur.starttime, cur.endtime)
              : 0,
        };
        o = o.concat(obj);
      }
      return o;
    }, []);
    return result;
  };

  const handleSelectTask = (event, index) => {
    if (index) {
      setSelectedTask(tasks.find((x) => x._id === index._id));
      setPage(1);
    } else {
      setSelectedTask(null);
      setPage(1);
    }
  };

  const renderTimeName = () => {
    switch (tab) {
      case 0:
        return "Tag";
      case 1:
        return "KW + Jahr";
      case 2:
        return "Monat + Jahr";
      default:
        return "Fehler";
    }
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const renderTimeFormat = (day) => {
    switch (tab) {
      case 0:
        return moment(day).format("LL");
      case 1:
        return moment(day).isoWeek() + " " + moment(day).year();
      case 2:
        return moment(day).format("MMMM") + " " + moment(day).year();
      default:
        return "Fehler";
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const renderTasks = () => {
    let filteredCustomers = sortedTasks
      .filter((data) =>
        selectedTask ? data.task._id === selectedTask._id : true,
      )
      .sort(function (a, b) {
        return new Date(b.day) - new Date(a.day);
      });
    const indexOfLastPost = page * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = filteredCustomers.slice(
      indexOfFirstPost,
      indexOfLastPost,
    );
    return (
      <Stack direction={"column"} spacing={1}>
        {currentItems.map((data, index) => (
          <>
            <motion.div key={index} variants={itemElement}>
              <Paper key={index}>
                <Stack direction={"column"} spacing={1} p={1}>
                  <Stack
                    pt={1}
                    direction={"row"}
                    spacing={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    px={1}
                  >
                    <Customer type={"text"} taskId={data.task._id} />
                    <Stack direction={"column"}>
                      <Typography variant={"body2"}>
                        {renderTimeName()}
                      </Typography>
                      <Typography variant={"h7"}>
                        {renderTimeFormat(data.day)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"column"} spacing={1}>
                    <Accordion sx={{ boxShadow: "none" }}>
                      <Card sx={{ borderRadius: "0px", border: "none" }}>
                        <CardContent
                          sx={{ borderRadius: "0px", border: "none" }}
                        >
                          <Box pr={1}>
                            <AccordionSummary expandIcon={<IoIosArrowDown />}>
                              <Stack
                                width={"100%"}
                                direction={"column"}
                                p={1}
                                spacing={1}
                                divider={<Divider />}
                              >
                                <Stack
                                  px={1}
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                >
                                  <Typography variant={"h7"}>
                                    Arbeitszeit
                                  </Typography>
                                  <Chip
                                    variant="outlined"
                                    label={moment
                                      .duration(data.worktime, "seconds")
                                      .format("hh:mm:ss", {
                                        trim: false,
                                      })}
                                  />
                                </Stack>
                              </Stack>
                            </AccordionSummary>
                          </Box>
                        </CardContent>
                      </Card>
                      <AccordionDetails>
                        <Stack
                          direction={"column"}
                          p={1}
                          spacing={1}
                          divider={<Divider />}
                        >
                          {reduceWorkTimes(data.times).map((time, index) => (
                            <Stack
                              key={index}
                              px={1}
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Stack
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                              >
                                <BsArrowReturnRight />
                                <User userId={time.user._id} />
                              </Stack>
                              <Chip
                                variant="outlined"
                                label={moment
                                  .duration(time.worktime, "seconds")
                                  .format("hh:mm:ss", {
                                    trim: false,
                                  })}
                              />
                            </Stack>
                          ))}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                  {renderTimes(data.times)}
                </Stack>
              </Paper>
            </motion.div>
          </>
        ))}
        <Paper>
          <Box p={1} display={"flex"} justifyContent={"center"}>
            <Pagination
              size="large"
              color={"primary"}
              count={Math.ceil(filteredCustomers.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        </Paper>
      </Stack>
    );
  };

  const renderTimes = (timeArray) => {
    return (
      <Accordion sx={{ boxShadow: "none" }}>
        <Card sx={{ borderRadius: "0px", border: "none" }}>
          <CardContent sx={{ borderRadius: "0px", border: "none" }}>
            <Box pr={1}>
              <AccordionSummary expandIcon={<IoIosArrowDown />}>
                <Stack
                  width={"100%"}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  p={1}
                  pl={2}
                >
                  <Typography variant={"h7"}>Protokoll</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={timeArray.length + " erfasste Zeit(en)"}
                    ></Chip>
                  </Box>
                </Stack>
              </AccordionSummary>
            </Box>
          </CardContent>
        </Card>
        <AccordionDetails>
          <Table size={"small"}>
            <TableBody>
              {timeArray.map((time, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack direction={"column"} spacing={0.5}>
                        <User type={"text"} userId={time?.user._id} />
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                          divider={<BsArrowRight />}
                        >
                          <Typography variant={"body1"}>
                            {moment(time.starttime).format("HH:mm:ss")}
                          </Typography>
                          <Typography variant={"body1"}>
                            {time.endtime
                              ? moment(time.endtime).format("HH:mm:ss")
                              : "Checked In"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Chip
                          variant="outlined"
                          label={
                            time.endtime
                              ? getDurationInHoursAndMinutesAndSeconds(
                                  time.starttime,
                                  time.endtime,
                                )
                              : "Checked In"
                          }
                        ></Chip>
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") && (
        <Paper>
          <Box p={1}>
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={1}>
                <Autocomplete
                  id="task"
                  onChange={handleSelectTask}
                  options={tasks}
                  fullWidth
                  getOptionLabel={(option) => option.customer}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Nach Auftrag filtern"}
                      variant="filled"
                      size={"small"}
                    />
                  )}
                />
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Button
                  disabled={tab === 0}
                  onClick={() => {
                    setTab(0);
                    setPage(1);
                  }}
                  fullWidth
                >
                  Tag
                </Button>
                <Button
                  disabled={tab === 1}
                  onClick={() => {
                    setTab(1);
                    setPage(1);
                  }}
                  fullWidth
                >
                  Woche
                </Button>
                <Button
                  disabled={tab === 2}
                  onClick={() => {
                    setTab(2);
                    setPage(1);
                  }}
                  fullWidth
                >
                  Monat
                </Button>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <FormControl fullWidth variant={"filled"}>
                  <InputLabel>Monat</InputLabel>
                  <Select
                    value={month}
                    label="Monat"
                    onChange={handleChangeMonth}
                  >
                    <MenuItem
                      value={moment().startOf("month").format("MMMM YYYY")}
                    >
                      {moment().startOf("month").format("MMMM YYYY")}
                    </MenuItem>
                    <MenuItem
                      value={moment()
                        .startOf("month")
                        .subtract(1, "month")
                        .format("MMMM YYYY")}
                    >
                      {moment()
                        .startOf("month")
                        .subtract(1, "month")
                        .format("MMMM YYYY")}
                    </MenuItem>
                    <MenuItem
                      value={moment()
                        .startOf("month")
                        .subtract(2, "month")
                        .format("MMMM YYYY")}
                    >
                      {moment()
                        .startOf("month")
                        .subtract(2, "month")
                        .format("MMMM YYYY")}
                    </MenuItem>
                    <MenuItem
                      value={moment()
                        .startOf("month")
                        .subtract(3, "month")
                        .format("MMMM YYYY")}
                    >
                      {moment()
                        .startOf("month")
                        .subtract(3, "month")
                        .format("MMMM YYYY")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <TaskHourExport times={times} month={month} />
              </Stack>
            </Stack>
          </Box>
        </Paper>
      )}
      {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") && (
        <motion.div variants={containerElement} initial="hidden" animate="show">
          <AnimatePresence mode={"wait"}>{renderTasks()}</AnimatePresence>
        </motion.div>
      )}
    </Stack>
  );
};

export default CustomerHistory;
