import { Box, Button, Paper, Stack } from "@mui/material";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import { useParams } from "react-router-dom";
import EmployeeHistory from "./EmployeeHistory";
import CustomerHistory from "./CustomerHistory";
import { useEffect, useState } from "react";
import { useUsers } from "../../../apollo/hooks/useUsers";

momentDurationFormatSetup(moment);

const TimeHistory = () => {
  const routeParams = useParams();

  const [section, setSection] = useState("employees");

  const { me, loading: usersLoading, error: usersError } = useUsers();

  useEffect(() => {
    setSection(routeParams.section);
  }, [routeParams.section]);

  if (usersLoading) return;
  if (usersError) return;

  const renderSection = () => {
    switch (section) {
      case "employees":
        return <EmployeeHistory />;
      case "customers":
        return <CustomerHistory />;
      default:
        return <EmployeeHistory />;
    }
  };

  return (
    <Stack direction={"column"} spacing={1}>
      {(me.me.role === "ADMIN" || me.me.role === "KOORDINATOR") && (
        <Paper>
          <Box p={1}>
            <Stack direction={"row"} spacing={1}>
              <Button
                disabled={section === "employees" || !section}
                onClick={() => setSection("employees")}
                fullWidth
              >
                Mitarbeiter
              </Button>
              <Button
                disabled={section === "customers"}
                onClick={() => setSection("customers")}
                fullWidth
              >
                Aufträge
              </Button>
            </Stack>
          </Box>
        </Paper>
      )}
      {renderSection()}
    </Stack>
  );
};

export default TimeHistory;
