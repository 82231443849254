import { Button } from "@mui/material";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

const ExcelExport = ({ excelData, fileName, buttonName, headline }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";

  function fitToColumn(data) {
    const widths = [];
    for (const field in data[0]) {
      widths.push({
        wch: Math.max(
          field.length,
          ...data.map((item) => item[field]?.toString()?.length ?? 0),
        ),
      });
    }
    return widths;
  }

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData, {
      skipHeader: false,
      origin: "A2",
    });

    XLSX.utils.sheet_add_json(ws, [{ note: headline }], {
      header: ["note"],
      skipHeader: true,
      origin: "A1",
    });

    ws["!cols"] = fitToColumn(excelData);

    ws["!rows"] = [{ hpt: 40 }];

    ws["A1"].s = {
      // styling for all cells
      font: {
        bold: true,
        sz: "13",
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: "1", // any truthy value here
      },
      fill: {
        type: "pattern",
        patternType: "solid",
        fgColor: { rgb: "e8f0f8" },
      },
    };

    ws["!merges"] = [
      {
        s: { c: 0, r: 0 },
        e: { c: Object.keys(excelData[0]).length - 1, r: 0 },
      },
    ];

    for (let i = 0; i < Object.keys(excelData[0]).length; i++) {
      const cell = ws[XLSX.utils.encode_cell({ r: 1, c: i })];
      if (!cell.s) {
        cell.s = {};
      }
      if (!cell.s.font) {
        cell.s.font = {};
      }
      // Set bold
      cell.s.font.bold = true;
      cell.s.font.fontSize = 14;
    }

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      disabled={excelData.length === 0}
      sx={{ height: "100%" }}
      size={"small"}
      onClick={() => exportToExcel(fileName)}
    >
      {buttonName}
    </Button>
  );
};

export default ExcelExport;
