import { gql } from "@apollo/client";
import { EXCUSE_ENTITY } from "../entities/excuseEntity";

export const UPDATE_EXCUSE = gql`
  mutation updateExcuse($input: ExcuseInput!, $_id: String!) {
    updateExcuse(input: $input, _id: $_id) {
      ...ExcuseEntity
    }
  }
  ${EXCUSE_ENTITY}
`;
