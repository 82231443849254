import { gql } from "@apollo/client";

export const TIME_ENTITY = gql`
  fragment TimeEntity on TimeEntity {
    _id
    starttime
    type
    endtime
    created_at
  }
`;
