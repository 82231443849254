import { gql } from "@apollo/client";
import { TASK_ENTITY } from "../entities/taskEntity";
import { TIME_ENTITY } from "../entities/timeEntity";

export const GET_TASK_BY_ID = gql`
  query getTaskById($_id: String!){
    getTaskById(_id: $_id) {
        ...TaskEntity
        times {
          ...TimeEntity
        }
    }
  }
  ${TASK_ENTITY},${TIME_ENTITY}
`;
